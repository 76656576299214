import { ISummary } from "src/defs";
import { CacheKeys } from "src/constants/cache-keys";
import cacheService, { CacheService } from "./cache.service";
import {
  SummaryFilterConfig,
  SummaryFilterConfigReset,
} from "src/configs/components-config";

export class SummaryConfigService {
  private _config: ISummary.ISummaryConfig = this.getDefaultConfig();
  constructor(private _cacheService: InstanceType<typeof CacheService>) {
    const config = _cacheService.getItemsSync(CacheKeys.SummaryFilterConfig);
    if (config) this.setConfig(config, false);
  }

  getDefaultConfig() {
    return {
      procedureType: SummaryFilterConfig.default.procedureType,
      startDate: SummaryFilterConfig.default.startDate,
      endDate: SummaryFilterConfig.default.endDate,
      search: "",
    };
  }

  getResetDefaultConfig() {
    return {
      startDate: SummaryFilterConfigReset.default.startDate,
      endDate: SummaryFilterConfigReset.default.endDate,
      procedureType: SummaryFilterConfigReset.default.procedureType,
      search: "",
    };
  }
  setConfig(config: ISummary.ISummaryConfig, persistant = false) {
    this._config = config;
    if (persistant) {
      this._cacheService.setItemSync(CacheKeys.SummaryFilterConfig, config);
    }
  }
  getConfig() {
    return this.getDefaultConfig();
  }
}

const summaryConfigService = new SummaryConfigService(cacheService);
export default summaryConfigService;
