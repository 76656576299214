import { LABEL_COLORS } from "./constant";
import {
  toothBasedProcedure,
  IMPLANTS_PROCEDURES,
  BRIDGE_PROCEDURES,
} from "src/constants/constant";
import userService from "src/services/user.service";
import { COLORS } from "src/constants/color-constants";
import {
  LOADING,
  NO_PREDICTION_POSSIBLE,
  SOURCE_IMAGES,
} from "src/constants/info-constant";
import { noXrayFound, sourceImageEmptyPrediction } from "./dummy";
import { isEmpty, flatMap, cloneDeep } from "lodash";

const allowedImpactedToothDisplay = [1, 16, 17, 32];

export const kidToothNumber = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
];

export const displayPanoImages = ["D7210", "D7250", "D7140"];

export const annotationDisplaRules = [
  {
    listOfProcedures: ["D2335"],
    allowFindings: [
      "bone_level",
      "apex_level",
      "Calculus",
      "Filling/Inlay",
      "Missing",
      "Root Canal",
      "Decay",
      "Attrition/Erosion",
      "Pontic",
      "Retainer",
      "Crown",
    ],
  },
  {
    listOfProcedures: ["D2332", "D2392", "D2391", "D2331"],
    allowFindings: ["bone_level", "apex_level", "Calculus", "Decay", "PARL"],
  },
  {
    listOfProcedures: [
      "D3346",
      "D3347",
      "D3348",
      "D3351",
      "D3352",
      "D3353",
      "D3355",
      "D3356",
      "D3357",
      "D3410",
      "D3421",
      "D3425",
      "D3426",
    ],
    allowFindings: ["Crown", "Decay"],
  },
  {
    listOfProcedures: ["D4341", "D4342"],
    allowFindings: ["bone_level", "apex_level", "Calculus"],
  },
  {
    listOfProcedures: [
      "D6010",
      "D6056",
      "D6057",
      "D6058",
      "D6059",
      "D6060",
      "D6061",
      "D6062",
      "D6063",
      "D6064",
      "D6065",
      "D6066",
      "D6068",
      "D6094",
      "D6190",
      "D6104",
    ],
    allowFindings: ["bone_level", "apex_level", "Calculus"],
  },
  {
    listOfProcedures: [
      "D4265",
      "D4210",
      "D4211",
      "D4240",
      "D4241",
      "D4260",
      "D4261",
      "D4263",
      "D4264",
      "D4265",
      "D4266",
      "D4267",
      "D4270",
      "D4273",
      "D4274",
      "D4275",
      "D4276",
      "D4277",
      "D4278",
      "D4283",
      "D4285",
    ],
    allowFindings: ["bone_level", "apex_level", "Calculus"],
  },
  {
    rangeOfProcedures: [
      {
        min: 2500,
        max: 2999,
      },
    ],
    allowFindings: [
      "bone_level",
      "apex_level",
      "Filling/Inlay",
      "Missing",
      "Root Canal",
      "Decay",
      "Attrition/Erosion",
      "Pontic",
      "Retainer",
      "Crown",
      "PARL",
    ],
  },
  {
    listOfProcedures: ["D7310"],
    allowFindings: [],
  },
];

export const processingStateMapper = {
  PENDING_IMAGE_DOWNLOAD: "Pending Image Download",
  PENDING_AI_REVIEW: "Pending AI Review",
  OVERJET_REVIEW_PROCESSING: "Overjet Review Processing",
  PENDING_ADE_CONSULTANT_REVIEW: "Pending Consultant Review",
  PENDING_DENTAL_CONSULTANT_REVIEW: "Pending Dental Consultant Review",
  READY_FOR_DELIVERY: "READY FOR DELIVERY",
  DELIVERED_SUCCESSFULLY: "Delivered Successfully",
};

/**
 * Utility function to convert Universal tooth number into FDI tooth number
 * Applies to Canadian client
 * @param {any} toothNumber
 * @returns String or Number
 */
export function toothNumberConversion(toothNumber) {
  const universalFDIToothMap =
    userService.getClientConfig()?.universalFDIToothMap;
  if (universalFDIToothMap) {
    return universalFDIToothMap[toothNumber];
  } else {
    return toothNumber;
  }
}

export function displayUniversalTooth(toothNumber) {
  const universalFDIToothMap =
    userService.getClientConfig()?.universalFDIToothMap;
  if (universalFDIToothMap) {
    return `(Universal: ${toothNumber})`;
  }
  return ``;
}

export function randomRGB() {
  var o = Math.round,
    r = Math.random,
    s = 255;
  return `${o(r() * s) + "," + o(r() * s) + "," + o(r() * s)}`;
}

export function getCanvasWidthAndHeight(props) {
  const canvasContainerWidth =
    document?.getElementsByClassName("mainCanvas")?.item(0)?.clientWidth ||
    props.width;
  const cavasContainerHeight =
    document?.getElementsByClassName("mainCanvas")?.item(0)?.clientHeight ||
    props.height;
  /**
   * The canvas container inside bootstrap row (mid col) need to be displayed center
   * As bootstrap divide <Col> into 12 equal parts
   * Below is the code to calculate the width of center <Col>. That should be the width of canvas Tag
   * For canvas height subtract footer area.
   */
  const height = parseInt(cavasContainerHeight) - 145;
  const width = (parseInt(canvasContainerWidth) / 12) * 10;

  return { height, width };
}

/**
 * Accept an array of prediction and remove empty object from start and end
 * To remove image reel separator / border
 * @param {Array of Object}  filteredPredictions
 */
export function removeEmptyObjectFromStartAndEnd(filteredPredictions) {
  if (Array.isArray(filteredPredictions) && filteredPredictions.length) {
    let length = filteredPredictions.length;
    if (isEmpty(filteredPredictions[0])) {
      filteredPredictions.splice(0, 1);
    }

    if (isEmpty(filteredPredictions[length - 1])) {
      filteredPredictions.splice(length - 1, 1);
    }
  }
}

/***
 * Accept all predictions and return prediction with Image location associated.
 * To only display image (without finding)
 */
export function displayXrayForKidTooth(predictionDetails) {
  let filtered = [];
  predictionDetails.forEach((predictionData) => {
    const placeHolderPrediction = JSON.parse(JSON.stringify(noXrayFound[0]));
    placeHolderPrediction.imagePredictionKey =
      predictionData.imagePredictionKey;
    placeHolderPrediction.reasons = predictionData.reasons;
    placeHolderPrediction.imageUri = predictionData.prediction.image_loc;
    placeHolderPrediction.prediction.classifications = {
      image_type: "",
    };
    filtered.push(placeHolderPrediction);
  });
  return filtered;
}

export function getTypeHeading(procedureCode, headingNumber) {
  // Heading number could be 1 or 2
  // 1 For criteria met and 2 for criteria not met
  let typeHeading = "Findings";
  let SRPcodes = [
    "D4341",
    "D4342",
    "4341",
    "4342",
    "D4240",
    "D4241",
    "D4260",
    "D4261",
    "D4263",
    "D4264",
    "D4265",
    "D4266",
    "D4267",
    "D4273",
    "D4910",
  ];
  if (SRPcodes.includes(procedureCode)) {
    if (headingNumber === 1) {
      typeHeading = "Acceptance Criteria Met";
    } else {
      typeHeading = "Acceptance Criteria Not Met";
    }
  }
  return typeHeading;
}

function setCanvasZoom(canvas, img, props) {
  // const canvas = this.props.canvas;
  const imgSize = {};

  if (!img) {
    img = canvas.backgroundImage;
  }
  const screenSize = getCanvasWidthAndHeight(props);

  const widthFactor = screenSize.width / img.width;
  const heightFactor = screenSize.height / img.height;
  const zoom = widthFactor < heightFactor ? widthFactor : heightFactor;

  imgSize.width = img.width * zoom;
  imgSize.height = img.height * zoom;

  return { imgSize, zoom };
}

export function getCircleRadius(zoomLevel) {
  const screenSize = getCanvasWidthAndHeight({});
  const measurement =
    screenSize.width < screenSize.height ? screenSize.width : screenSize.height;
  return measurement / (zoomLevel * 300);
}

export function setCanvasBackgroundImage(canvas, img, props) {
  const imgNewdimension = setCanvasZoom(canvas, img, props);
  const left = (canvas.width - img.width * imgNewdimension.zoom) / 2;
  canvas.setViewportTransform([
    imgNewdimension.zoom,
    0,
    0,
    imgNewdimension.zoom,
    left,
    0,
  ]);

  canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
    left: 0,
    top: 0,
  });

  if (props.setCanvasPropertiesValue) {
    props.setCanvasPropertiesValue(imgNewdimension.zoom);
    const radius = getCircleRadius(imgNewdimension.zoom);
    setEdgeCricleRadius(canvas, radius);
  }

  if (props.setPolygonsBackgroundImage) {
    props.setPolygonsBackgroundImage(img);
  }
}

export function validatePoint(maxPoint, point) {
  if (point.x < 0) {
    point.x = 0;
  }
  if (point.y < 0) {
    point.y = 0;
  }
  if (point.x > maxPoint.x) {
    point.x = maxPoint.x;
  }
  if (point.y > maxPoint.y) {
    point.y = maxPoint.y;
  }

  return point;
}

function setEdgeCricleRadius(canvas, radius) {
  const { criteriaMet } = userService.getCanvasConfig();
  canvas.getObjects().forEach((o) => {
    if (o.isCricle) {
      o.setRadius(radius * 2);
    }
    // o.setStrokeWidth(radius);
  });
  if (criteriaMet?.showApexPoint === false) {
    canvas.getObjects().forEach((item) => {
      if (item.parentObjectName === "apex_level") {
        item.radius = 0;
      }
      canvas.renderAll();
    });
  }
  canvas.renderAll();
}

export function scrollToThumbnail(activeRadiographId) {
  const element = document.getElementById(
    `thumbnail-image-${activeRadiographId}`
  );

  if (element) {
    element.scrollIntoView({ inline: "center" });
  }
}

// TODO: Junaid 9-7-2020: The above function needs to be refactor, all commented code will be usefull of PA

/**
 * This function will return polygon which will help to plot Bone level, Bone loss, Apex point,
 * Calculus, RCT, Crown and Restoration
 * @param {object} data Prediction json resonse
 * @param {number} claimTooth claim tooth number
 * @param {string} reviewResult overjet review result
 */
export function setPolygonData(
  data,
  claimTooth,
  reviewResult,
  procedureCode,
  skipBoneRatio = false
) {
  const canvasConfig = userService.getCanvasConfig();
  const polygons = {};
  const findingRules = annotationDisplaRules.find((annotationDisplaRule) => {
    let annotationruleExists = false;
    if (
      annotationDisplaRule?.listOfProcedures &&
      annotationDisplaRule.listOfProcedures.includes(procedureCode)
    ) {
      annotationruleExists = true;
    } else if (annotationDisplaRule.rangeOfProcedures) {
      let procedureValue = procedureCode?.slice(
        procedureCode?.indexOf("D") + 1
      );
      annotationDisplaRule.rangeOfProcedures?.forEach((procedureRange) => {
        if (
          procedureValue >= procedureRange.min &&
          procedureValue <= procedureRange.max
        ) {
          annotationruleExists = true;
        }
        return annotationruleExists;
      });
    }
    return annotationruleExists;
  });

  if (data?.classifications && data.classifications?.image_type === "loading") {
    polygons[LOADING] = { class_type: "Info" };
    return polygons;
  }

  if (data?.classifications && data.classifications?.image_type === "source") {
    polygons[SOURCE_IMAGES] = { class_type: "Info" };
    return polygons;
  }

  // TODO: Prashanth 2020-08-04: This is a temporary check to show images without any predictions
  if (
    (data?.classifications && data.classifications?.image_type === "na") ||
    !data.tooth_number ||
    !Object.keys(data.tooth_number).length
  ) {
    polygons[NO_PREDICTION_POSSIBLE] = { class_type: "Info" };
    return polygons;
  }

  if (data?.classifications && data.classifications?.image_type === "Loading") {
    polygons[LOADING] = { class_type: "Info" };
    return polygons;
  }

  /**
   * Utility function to include only applicable teeth. This is for when the canvas/claim
   * is for a given tooth. In that case, show only that tooth's details.
   * This function will remove other teeth's geometry and calculus_is_on_enamel from the given object.
   *
   * @param {Object} obj the sub object whose properties should be filtered
   */
  const filterToothNumber = (obj, claimTooth) => {
    if (!claimTooth) return;
    for (let i = obj.tooth_number.length - 1; i >= 0; i--) {
      let toothNumber;
      toothNumber = obj.tooth_number[i][0];
      if (claimTooth !== Number(toothNumber)) {
        delete obj.geometry[i];
        delete obj.tooth_number[i];
        if (obj.calculus_is_on_enamel) delete obj.calculus_is_on_enamel[i];
      }
    }
  };

  /**
   * Normalize Bridge Object
   * @param {Object} obj
   * @param {String} tooth
   * @param {Object} geometry
   * @returns Object
   */
  const normalizeBridgeObject = (obj, tooth, geometry) => {
    return {
      class_type: "Restorative",
      geometry_type: "polygon",
      geometry: [geometry],
      tooth_number: [[String(tooth)]],
      metaclass_type: obj.metaclass_type,
      prediction_size_params: obj.prediction_size_params,
      id: obj.id,
    };
  };

  /**
   * Utility function to include only applicable teeth. This is for when the canvas/claim
   * is for a given tooth. In that case, show only that tooth's details.
   * This function will remove other teeth from the given object.
   *
   * @param {Object} obj the sub object whose properties should be filtered
   */
  const filterTooth = (obj, claimTooth) => {
    if (!claimTooth) return;
    const keys = Object.keys(obj);
    for (let i = keys.length - 1; i >= 0; i--) {
      const tooth = keys[i];
      if (claimTooth !== +tooth) delete obj[tooth];
    }
  };

  const hasValue = (o) => o && Object.keys(o).length;
  if (data.disease && data.disease.combined_decay) {
    filterToothNumber(data.disease.combined_decay, claimTooth);
    let decay = data.disease.combined_decay;
    decay.metaclass_type = "Findings";
    polygons["Decay"] = {
      ...decay,
      color: LABEL_COLORS["Decay"],
      parentObjectName: "Decay",
    };
  }
  if (data?.disease?.wearerosion) {
    filterToothNumber(data.disease.wearerosion, claimTooth);
    let wearerosion = data.disease.wearerosion;
    wearerosion.metaclass_type = "Findings";
    polygons["Attrition/Erosion"] = {
      ...wearerosion,
      color: LABEL_COLORS["Attrition/Erosion"],
      parentObjectName: "Attrition/Erosion",
    };
  }
  if (data?.disease?.missing) {
    filterToothNumber(data.disease.missing, claimTooth);
    let missing = data.disease.missing;
    missing.metaclass_type = "Findings";
    polygons["Missing"] = {
      ...missing,
      color: LABEL_COLORS["Missing"],
      parentObjectName: "Missing",
    };
  }

  // TODO: ICW-1151: This should be enable after configurable canvas display
  if (data?.disease?.parl && BRIDGE_PROCEDURES.includes(procedureCode)) {
    filterToothNumber(data.disease.parl, claimTooth);
    let parl = data.disease.parl;
    parl.metaclass_type = "Findings";
    polygons["PARL"] = {
      ...parl,
      color: LABEL_COLORS["Parl"],
      parentObjectName: "Parl",
    };
  }

  // procedureCode.startsWith("2") <-- Show tooth on pano for Sunlife
  if (
    procedureCode.startsWith("2") &&
    data?.classifications?.image_type === "pan"
  ) {
    let toothMaskObj = data.tooth_number.find(
      (item) => String(item.label) === String(claimTooth)
    );
    if (toothMaskObj && !isEmpty(toothMaskObj.geometry)) {
      polygons.Tooth = {
        tooth_number: [],
        geometry: [],
        geometry_type: "polygon",
        metaclass_type: "Findings",
        parentObjectName: "Teeth",
      };
      polygons.Tooth.tooth_number[0] = [toothMaskObj.label];
      polygons.Tooth.geometry[0] = toothMaskObj.geometry[0];
      polygons.Tooth.color = LABEL_COLORS["ClaimedTooth"];
    }
  }

  delete data.disease;

  if (procedureCode.startsWith("D6") || procedureCode.startsWith("D2")) {
    if (data.bridge && data.bridge.Bridge && data.bridge.Bridge.tooth_number) {
      let toothIndex = -1;
      const tooth = data.bridge.Bridge.tooth_number.find((item, index) => {
        const toothNumber = Array.isArray(item)
          ? Number(item[0])
          : Number(item);
        if (toothNumber === Number(claimTooth)) {
          toothIndex = index;
          return true;
        }
        return false;
      });
      if (tooth && toothIndex > -1) {
        const toothMaskObj = normalizeBridgeObject(
          data.bridge.Bridge,
          tooth,
          data.bridge.Bridge.geometry[toothIndex]
        );
        let label = flatMap(data.bridge.Bridge.bridge_subcategory, (tooths) => {
          return tooths.map((tooth) => tooth);
        }).find((toothObj) => {
          const key = Object.keys(toothObj).pop();
          if (Number(tooth) === Number(key)) {
            return true;
          }
          return false;
        });
        label =
          Object.values(label).pop() === "retainer" ? "Retainer" : "Pontic";
        const labelColor =
          label === "Retainer"
            ? LABEL_COLORS["Retainer"]
            : LABEL_COLORS["Pontic"];
        if (toothMaskObj && !isEmpty(toothMaskObj)) {
          let toothMastObjData = toothMaskObj;
          toothMastObjData.metaclass_type = "Findings";
          polygons[`${label}`] = {
            ...toothMastObjData,
            color: labelColor,
            parentObjectName: label,
          };
        }
      }
    }
  }
  // Calculations for Perio
  if (data.perio) {
    Object.keys(data.perio).forEach((item) => {
      if (hasValue(data.perio[item])) {
        polygons[item] = {
          ...data.perio[item],
          parentObjectName: "perio",
        };
      }
    });
  }
  delete data.perio;

  // Calculations for furcation
  // TODO: Prashanth 2020-08-19: Furcation should be shown for only DentaQuest
  if (
    canvasConfig.showFurcation &&
    data.furcation_involvement &&
    hasValue(data.furcation_involvement)
  ) {
    // TODO: Junaid 2020-08-05 Remove this hard coded values once we get complete furcation
    polygons["Furcation"] = {
      parentObjectName: "furcation",
      geometry_type: "bbox",
      color: "184, 233, 134",
      type: ["Furcation"],
      subType: "Furcation",
      metaclass_type: "Furcation",
      tooth_number: [],
      geometry: [],
    };
    filterTooth(data.furcation_involvement, claimTooth);
    Object.keys(data.furcation_involvement).forEach((tooth) => {
      const furcation = data.furcation_involvement[tooth];
      if (furcation.involvement === "present") {
        polygons.Furcation.tooth_number.push([`${tooth}`]);
        polygons.Furcation.geometry.push(furcation.geometry[0]);
      }
    });
  }
  delete data.furcation_involvement;

  // Calculations for Bone Level
  if (data.bone_level && hasValue(data.bone_level.tooth) && !skipBoneRatio) {
    filterTooth(data.bone_level.tooth, claimTooth);
    polygons.bone_level = {
      ...data.bone_level.tooth,
      parentObjectName: "bone_level",
    };
  }
  delete data.bone_level;
  // Calculations for Apex Level
  if (data.apex_level && hasValue(data.apex_level.tooth) && !skipBoneRatio) {
    filterTooth(data.apex_level.tooth, claimTooth);
    polygons.apex_level = {
      ...data.apex_level.tooth,
      parentObjectName: "apex_level",
    };
  }
  delete data.apex_level;
  // TODO: Prashanth 2020-06-03: Temporary check for PA
  if (data.classifications && data.classifications.image_type === "pa") {
    // For PAs, make sure that:
    // - bone levels that do not have apex ratio are not included
    // - bone levels do not show labels
    if (polygons.bone_level) {
      Object.keys(polygons.bone_level).forEach((k) => {
        if (k === "parentObjectName") return;
        // Delete bone level is the corresponding apex level is not present
        const toothBoneLevel = polygons.bone_level[k];
        const apexTooth = polygons.apex_level && polygons.apex_level[k];
        if (!apexTooth) {
          toothBoneLevel.dist = [-1, -1];
        } else if (apexTooth.dist) {
          apexTooth.apex_on_image_boundary =
            apexTooth.apex_on_image_boundary || [false, false];
          if (
            apexTooth.dist[0] === -1 ||
            (apexTooth.apex_on_image_boundary &&
              apexTooth.apex_on_image_boundary[0])
          )
            toothBoneLevel.dist[0] = -1;
          if (
            apexTooth.dist[1] === -1 ||
            (apexTooth.apex_on_image_boundary &&
              apexTooth.apex_on_image_boundary[1])
          )
            toothBoneLevel.dist[1] = -1;
          toothBoneLevel.hideLabel = true;
          toothBoneLevel.hideLine = true;
          polygons.apex_level[k].isPa = true;
          toothBoneLevel.strokeWidth = 0;
        }
      });
    }
  }

  // Calculations for restoration
  if (data.restoration) {
    Object.keys(data.restoration).forEach((item) => {
      if (
        data.classifications &&
        data.classifications.image_type !== "pa" &&
        item === "RootCanal"
      )
        return;
      if (hasValue(data.restoration[item])) {
        filterToothNumber(data.restoration[item], claimTooth);
        let restoration = data.restoration[item];
        restoration.metaclass_type = "Findings";
        polygons[item] = {
          ...restoration,
          color: getColorMatchedWithResult(reviewResult, "Restoration"),
          parentObjectName: "restoration",
        };
      }
    });
  }
  delete data.restoration;

  //Calculations for root canal

  if (data.rct) {
    Object.keys(data.rct).forEach((item) => {
      // if (data.classifications.image_type !== 'pa') return;
      if (hasValue(data.rct[item])) {
        filterToothNumber(data.rct[item], claimTooth);
        let rct = data.rct[item];
        rct.metaclass_type = "Findings";
        polygons["Root Canal"] = {
          ...rct,
          color: getColorMatchedWithResult(reviewResult, "RootCanal"),
          parentObjectName: "rct",
        };
      }
    });
  }
  delete data.rct;

  // Calculations for crown
  if (data.crown && data.crown) {
    Object.keys(data.crown).forEach((item) => {
      // if (data.classifications.image_type !== 'pa') return;
      if (hasValue(data.crown[item])) {
        filterToothNumber(data.crown[item], claimTooth);
        let crown = data.crown[item];
        crown.metaclass_type = "Findings";
        polygons[item] = {
          ...crown,
          color: getColorMatchedWithResult(reviewResult, "Crown"),
          parentObjectName: "crown",
        };
      }
    });
  }
  delete data.crown;

  Object.keys(polygons).forEach((item) => {
    if (
      polygons[item].tooth_number &&
      !polygons[item].tooth_number.filter(String).length
    ) {
      delete polygons[item];
    }
  });
  // !procedureCode.startsWith("2") &&  <-- Added to handle sunlife hide all tooth on pano
  // if (!procedureCode.startsWith("2") && data?.classifications?.image_type === "pan") {
  //   polygons.Tooth = {
  //     tooth_number: [],
  //     geometry: [],
  //     color: [],
  //     geometry_type: "polygon",
  //     metaclass_type: "Findings",
  //     parentObjectName: "Teeth",
  //   };
  //   data?.tooth_number.forEach((item, i) => {
  //     polygons.Tooth.tooth_number[i] = [item.label];
  //     polygons.Tooth.geometry[i] = item.geometry[0];
  //     polygons.Tooth.color = "255,255,255"
  //   });
  // }

  if (
    data.classifications &&
    data.classifications.image_type === "pan" &&
    data.impaction &&
    data.impaction.length &&
    allowedImpactedToothDisplay.includes(claimTooth)
  ) {
    filterImpactedTooth(data, claimTooth);
    data.impaction.forEach((item, i) => {
      if (
        item?.impacted?.geometry.length === 0 &&
        item?.erupted?.geometry.length === 0
      )
        return;
      if (item?.impacted?.geometry) {
        const calculateRatio =
          item.ratio >= 0 ? (item.ratio * 100).toFixed() : "";
        let impactedGeometery = item?.impacted?.geometry || [];
        let impactedGeometeryType = item?.impacted.geometry_type || "polygon";
        let d = {
          id: item.label,
          tooth_number: item.label,
          geometry: impactedGeometery,
          geometry_type: impactedGeometeryType,
          metaclass_type: "Tooth and Impaction Level",
          color: LABEL_COLORS.ImplantImpacted,
        };
        polygons[`Tooth #${d.tooth_number} Impacted ${calculateRatio} %`] = {
          ...d,
          parentObjectName: "wisdomTooth",
        };
      }
      if (item?.erupted?.geometry) {
        const calculateRatio = item.ratio;
        let impactedGeometery = item?.erupted?.geometry || [];
        let impactedGeometeryType = item?.erupted.geometry_type || "polygon";
        let d = {
          id: item.label,
          tooth_number: item.label,
          geometry: impactedGeometery,
          geometry_type: impactedGeometeryType,
          metaclass_type: "Tooth and Impaction Level",
          color: LABEL_COLORS.ImplantErupted,
        };
        polygons[`Tooth #${d.tooth_number} Erupted ${calculateRatio} %`] = {
          ...d,
          parentObjectName: "wisdomTooth",
        };
      }
    });
  }

  // Remove Crown if Retainer and/or Pontic is present
  if (polygons["Retainer"] || polygons["Pontic"]) {
    delete polygons["Crown"];
  }

  if (!findingRules) {
    return polygons;
  } else {
    let finalPolygons = {};
    findingRules.allowFindings.forEach((finding) => {
      if (polygons[finding]) {
        finalPolygons = { ...finalPolygons, [finding]: polygons[finding] };
      }
    });
    return finalPolygons;
  }
}

export function setPonticData(
  data,
  claimTooth,
  reviewResult,
  procedureCode,
  toothDetails,
  orgClaimId
) {
  const polygons = {};

  if (data.classifications && data.classifications.image_type === "loading") {
    polygons[LOADING] = { class_type: "Info" };
    return polygons;
  }

  if (data.classifications && data.classifications.image_type === "source") {
    polygons[SOURCE_IMAGES] = { class_type: "Info" };
    return polygons;
  }

  // TODO: Prashanth 2020-08-04: This is a temporary check to show images without any predictions
  if (
    (data.classifications && data.classifications.image_type === "na") ||
    !data.tooth_number ||
    !Object.keys(data.tooth_number).length
  ) {
    polygons[NO_PREDICTION_POSSIBLE] = { class_type: "Info" };
    return polygons;
  }

  if (data.classifications && data.classifications.image_type === "Loading") {
    polygons[LOADING] = { class_type: "Info" };
    return polygons;
  }

  /**
   * Normalize Bridge Object
   * @param {Object} obj
   * @param {String} tooth
   * @param {Object} geometry
   * @returns Object
   */
  const normalizeBridgeObject = (obj, tooth, geometry) => {
    return {
      class_type: "Restorative",
      geometry_type: "polygon",
      geometry: [geometry],
      tooth_number: [[String(tooth)]],
      metaclass_type: obj.metaclass_type,
      prediction_size_params: obj.prediction_size_params,
      id: obj.id,
    };
  };

  if (procedureCode.startsWith("D6")) {
    if (data.bridge && data.bridge.Bridge && data.bridge.Bridge.tooth_number) {
      let toothIndex = -1;
      const tooth = data.bridge.Bridge.tooth_number.find((item, index) => {
        const toothNumber = Array.isArray(item)
          ? Number(item[0])
          : Number(item);
        if (toothNumber === Number(claimTooth)) {
          toothIndex = index;
          return true;
        }
        return false;
      });
      if (tooth && toothIndex > -1) {
        const toothMaskObj = normalizeBridgeObject(
          data.bridge.Bridge,
          tooth,
          data.bridge.Bridge.geometry[toothIndex]
        );
        let label = flatMap(data.bridge.Bridge.bridge_subcategory, (tooths) => {
          return tooths.map((tooth) => tooth);
        }).find((toothObj) => {
          const key = Object.keys(toothObj).pop();
          if (Number(tooth) === Number(key)) {
            return true;
          }
          return false;
        });
        label =
          Object.values(label).pop() === "retainer" ? "Retainer" : "Pontic";
        const labelColor =
          label === "Retainer"
            ? LABEL_COLORS["Retainer"]
            : LABEL_COLORS["Pontic"];
        if (toothMaskObj && !isEmpty(toothMaskObj)) {
          let toothMastData = toothMaskObj;
          toothMastData.metaclass_type = "Findings";
          polygons[`${claimTooth} ${label}`] = {
            ...toothMastData,
            color: labelColor,
            parentObjectName: label,
          };
        }
      }
    }
  }
  Object.keys(polygons).forEach((item) => {
    if (
      polygons[item].tooth_number &&
      !polygons[item].tooth_number.filter(String).length
    ) {
      delete polygons[item];
    }
  });

  return polygons;
}

/**
 * This function will return polygon which will help to plot Bone level and Implant tooth mask
 *
 * @param {object} data Prediction json resonse
 * @param {number} claimTooth claim tooth number
 * @param {string} reviewResult overjet review result
 */
export function setImplantData(data, claimTooth, procedureCode) {
  if (!data) return {};
  const polygons = {};

  if (data.classifications && data.classifications.image_type === "loading") {
    polygons[LOADING] = { class_type: "Info" };
    return polygons;
  }

  if (data.classifications && data.classifications.image_type === "source") {
    polygons[SOURCE_IMAGES] = { class_type: "Info" };
    return polygons;
  }

  // TODO: Prashanth 2020-08-04: This is a temporary check to show images without any predictions
  if (
    (data.classifications && data.classifications.image_type === "na") ||
    !data.tooth_number ||
    !Object.keys(data.tooth_number).length
  ) {
    polygons[NO_PREDICTION_POSSIBLE] = { class_type: "Info" };
    return polygons;
  }

  if (data.classifications && data.classifications.image_type === "Loading") {
    polygons[LOADING] = { class_type: "Info" };
    return polygons;
  }

  /**
   * Utility function to include only applicable teeth. This is for when the canvas/claim
   * is for a given tooth. In that case, show only that tooth's details.
   * This function will remove other teeth from the given object.
   *
   * @param {Object} obj the sub object whose properties should be filtered
   */

  if (data?.restoration?.implant) {
    data.restoration.implant.tooth_number.forEach((toothNumber) => {
      if (toothNumber[0] >= 1 && toothNumber[0] <= 32) {
        delete data.bone_level.tooth[toothNumber[0]];
      }
    });
  }

  const filterTooth = (obj) => {
    const keys = Object.keys(obj);
    for (let i = keys.length - 1; i >= 0; i--) {
      const tooth = keys[i];
      // To display bone level and ratio for all tooth submitted
      if (!(+tooth >= 1 && +tooth <= 32)) {
        delete obj[tooth];
      }
    }
  };

  const hasValue = (o) => o && Object.keys(o).length;
  if (procedureCode.startsWith("D6")) {
    let label = "Implant";
    let toothColor = LABEL_COLORS["Implant"];
    if (data?.restoration?.implant) {
      data.restoration.implant.tooth_number.forEach((item, index) => {
        if (String(item) === String(claimTooth)) {
          if (data.restoration.implant.geometry?.length) {
            let polyData = {
              geometry_type: "polygon",
              metaclass_type: "Findings",
              geometry: [data.restoration.implant.geometry[index]],
            };
            polygons[`${claimTooth} ${label}`] = {
              ...polyData,
              color: toothColor,
              parentObjectName: "Pontic",
            };
          }
        }
      });
    }
  }

  // Calculations for Calculus
  if (
    data.disease &&
    data.disease.Calculus &&
    hasValue(data.disease.Calculus)
  ) {
    polygons.Calculus = {
      ...data.disease.Calculus,
      color: LABEL_COLORS.Calculus,
      parentObjectName: "Calculus",
    };
  }
  delete data.disease;

  // Calculations for Bone Level
  if (data.bone_level && hasValue(data.bone_level.tooth)) {
    filterTooth(data.bone_level.tooth, claimTooth);
    polygons.bone_level = {
      ...data.bone_level.tooth,
      parentObjectName: "bone_level",
    };
  }
  delete data.bone_level;
  // Calculations for Apex Level
  if (data.apex_level && hasValue(data.apex_level.tooth)) {
    filterTooth(data.apex_level.tooth, claimTooth);
    polygons.apex_level = {
      ...data.apex_level.tooth,
      parentObjectName: "apex_level",
    };
  }
  delete data.apex_level;
  // TODO: Prashanth 2020-06-03: Temporary check for PA
  if (data.classifications && data.classifications.image_type === "pa") {
    // For PAs, make sure that:
    // - bone levels that do not have apex ratio are not included
    // - bone levels do not show labels
    if (polygons.bone_level) {
      Object.keys(polygons.bone_level).forEach((k) => {
        if (k === "parentObjectName") return;
        // Delete bone level is the corresponding apex level is not present
        const toothBoneLevel = polygons.bone_level[k];
        const apexTooth = polygons.apex_level && polygons.apex_level[k];
        if (!apexTooth) {
          toothBoneLevel.dist = [-1, -1];
        } else if (apexTooth.dist) {
          apexTooth.apex_on_image_boundary =
            apexTooth.apex_on_image_boundary || [false, false];
          if (
            apexTooth.dist[0] === -1 ||
            (apexTooth.apex_on_image_boundary &&
              apexTooth.apex_on_image_boundary[0])
          )
            toothBoneLevel.dist[0] = -1;
          if (
            apexTooth.dist[1] === -1 ||
            (apexTooth.apex_on_image_boundary &&
              apexTooth.apex_on_image_boundary[1])
          )
            toothBoneLevel.dist[1] = -1;
          toothBoneLevel.hideLabel = true;
          toothBoneLevel.hideLine = true;
          polygons.apex_level[k].isPa = true;
          toothBoneLevel.strokeWidth = 0;
        }
      });
    }
  }

  Object.keys(polygons).forEach((item) => {
    if (
      polygons[item].tooth_number &&
      !polygons[item].tooth_number.filter(String).length
    ) {
      delete polygons[item];
    }
  });

  // TODO: ICW-1151: This should be enable after configurable canvas display
  // if (data?.classifications?.image_type === "pan") {
  //   polygons.Tooth = {
  //     tooth_number: [],
  //     geometry: [],
  //     geometry_type: "polygon",
  //     metaclass_type: "Findings",
  //     parentObjectName: "Teeth",
  //   };
  //   data?.tooth_number.forEach((item, i) => {
  //     polygons.Tooth.tooth_number[i] = [item.label];
  //     polygons.Tooth.geometry[i] = item.geometry[0];
  //   });
  // }
  return polygons;
}

/**
 * Utility function to include only applicable teeth. This is for when the canvas/claim
 * is for a given tooth. In that case, show only that tooth's details.
 * This function will remove other teeth's geometry and calculus_is_on_enamel from the given object.
 *
 * @param {Object} obj the sub object whose properties should be filtered
 */
const filterImpactedTooth = (obj, claimTooth) => {
  if (!claimTooth) return;
  for (let i = obj.impaction.length - 1; i >= 0; i--) {
    const toothNumber = obj.impaction[i]["label"];
    if (claimTooth !== Number(toothNumber)) {
      delete obj.impaction[i];
    }
  }
};

export function setSourceImages(sourceImages, filteredData) {
  if (sourceImages) {
    Object.values(sourceImages).forEach((element, i) => {
      let emptyPrediction = cloneDeep(sourceImageEmptyPrediction[0]);
      if (typeof element === "object") {
        emptyPrediction.imageUri = element.sourceImage;
        emptyPrediction.sourceImages = element.sourceImage;
        emptyPrediction.imageReceiptDate = element.imageReceiptDate;
        emptyPrediction.isNewTag = element.isNewTag;
      } else {
        emptyPrediction.imageUri = element;
        emptyPrediction.sourceImages = element;
      }
      emptyPrediction.imagePredictionKey = Object.keys(sourceImages)[i];
      filteredData.push(emptyPrediction);
    });
  }
}

export function setPanoData(
  data,
  claimTooth,
  procedureCode,
  orgClaimId,
  reviewResult
) {
  const findingRules = annotationDisplaRules.find((annotationDisplaRule) => {
    let annotationruleExists = false;
    if (
      annotationDisplaRule?.listOfProcedures &&
      annotationDisplaRule.listOfProcedures.includes(procedureCode)
    ) {
      annotationruleExists = true;
    } else if (annotationDisplaRule.rangeOfProcedures) {
      let procedureValue = procedureCode?.slice(
        procedureCode?.indexOf("D") + 1
      );
      annotationDisplaRule.rangeOfProcedures?.forEach((procedureRange) => {
        if (
          procedureValue >= procedureRange.min &&
          procedureValue <= procedureRange.max
        ) {
          annotationruleExists = true;
        }
        return annotationruleExists;
      });
    }
    return annotationruleExists;
  });

  const polygons = {};
  const filterToothNumber = (obj, claimTooth) => {
    if (!claimTooth) return;
    for (let i = obj.tooth_number.length - 1; i >= 0; i--) {
      let toothNumber;
      toothNumber = obj.tooth_number[i][0];
      if (claimTooth !== Number(toothNumber)) {
        delete obj.geometry[i];
        delete obj.tooth_number[i];
        if (obj.calculus_is_on_enamel) delete obj.calculus_is_on_enamel[i];
      }
    }
  };

  const filterTooth = (obj, claimTooth) => {
    if (!claimTooth) return;
    const keys = Object.keys(obj);
    for (let i = keys.length - 1; i >= 0; i--) {
      const tooth = keys[i];
      if (claimTooth !== +tooth) delete obj[tooth];
    }
  };

  const hasValue = (o) => o && Object.keys(o).length;

  if (data.bone_level && hasValue(data.bone_level.tooth)) {
    filterTooth(data.bone_level.tooth, claimTooth);
    polygons.bone_level = {
      ...data.bone_level.tooth,
      parentObjectName: "bone_level",
    };
  }
  delete data.bone_level;

  // Calculations for Apex Level
  if (data.apex_level && hasValue(data.apex_level.tooth)) {
    filterTooth(data.apex_level.tooth, claimTooth);
    polygons.apex_level = {
      ...data.apex_level.tooth,
      parentObjectName: "apex_level",
    };
  }
  delete data.apex_level;

  if (data.classifications && data.classifications.image_type === "pa") {
    // For PAs, make sure that:
    // - bone levels that do not have apex ratio are not included
    // - bone levels do not show labels
    if (polygons.bone_level) {
      Object.keys(polygons.bone_level).forEach((k) => {
        if (k === "parentObjectName") return;
        // Delete bone level is the corresponding apex level is not present
        const toothBoneLevel = polygons.bone_level[k];
        const apexTooth = polygons.apex_level && polygons.apex_level[k];
        if (!apexTooth) {
          toothBoneLevel.dist = [-1, -1];
        } else if (apexTooth.dist) {
          apexTooth.apex_on_image_boundary =
            apexTooth.apex_on_image_boundary || [false, false];
          if (
            apexTooth.dist[0] === -1 ||
            (apexTooth.apex_on_image_boundary &&
              apexTooth.apex_on_image_boundary[0])
          )
            toothBoneLevel.dist[0] = -1;
          if (
            apexTooth.dist[1] === -1 ||
            (apexTooth.apex_on_image_boundary &&
              apexTooth.apex_on_image_boundary[1])
          )
            toothBoneLevel.dist[1] = -1;
          toothBoneLevel.hideLabel = true;
          toothBoneLevel.hideLine = true;
          polygons.apex_level[k].isPa = true;
          toothBoneLevel.strokeWidth = 0;
        }
      });
    }
  }

  if (data.rct) {
    Object.keys(data.rct).forEach((item) => {
      // if (data.classifications.image_type !== 'pa') return;
      if (hasValue(data.rct[item])) {
        filterToothNumber(data.rct[item], claimTooth);
        let rct = data.rct[item];
        rct.metaclass_type = "Findings";
        polygons["Root Canal"] = {
          ...rct,
          color: getColorMatchedWithResult(reviewResult, "RootCanal"),
          parentObjectName: "rct",
        };
      }
    });
  }
  delete data.rct;

  if (data.crown && data.crown) {
    Object.keys(data.crown).forEach((item) => {
      // if (data.classifications.image_type !== 'pa') return;
      if (hasValue(data.crown[item])) {
        filterToothNumber(data.crown[item], claimTooth);
        let crown = data.crown[item];
        crown.metaclass_type = "Findings";
        polygons[item] = {
          ...crown,
          color: getColorMatchedWithResult(reviewResult, "Crown"),
          parentObjectName: "crown",
        };
      }
    });
  }
  delete data.crown;

  if (data.restoration) {
    Object.keys(data.restoration).forEach((item) => {
      if (
        data.classifications &&
        data.classifications.image_type !== "pa" &&
        item === "RootCanal"
      )
        return;
      if (hasValue(data.restoration[item])) {
        filterToothNumber(data.restoration[item], claimTooth);
        let restoration = data.restoration[item];
        restoration.metaclass_type = "Findings";
        polygons[item] = {
          ...restoration,
          color: getColorMatchedWithResult(reviewResult, "Restoration"),
          parentObjectName: "restoration",
        };
      }
    });
  }
  delete data.restoration;

  if (
    data.impaction &&
    data.impaction.length &&
    allowedImpactedToothDisplay.includes(claimTooth)
  ) {
    filterImpactedTooth(data, claimTooth);
    data.impaction.forEach((item, i) => {
      if (
        item?.impacted?.geometry.length === 0 &&
        item?.erupted?.geometry.length === 0
      )
        return;
      if (item?.impacted?.geometry) {
        const calculateRatio =
          item.ratio >= 0 ? (item.ratio * 100).toFixed() : "";
        let impactedGeometery = item?.impacted?.geometry || [];
        let impactedGeometeryType = item?.impacted.geometry_type || "polygon";
        let d = {
          id: item.label,
          tooth_number: item.label,
          geometry: impactedGeometery,
          geometry_type: impactedGeometeryType,
          metaclass_type: "Tooth and Impaction Level",
          color: LABEL_COLORS.ImplantImpacted,
        };
        polygons[`Tooth #${d.tooth_number} Impacted ${calculateRatio} %`] = {
          ...d,
          parentObjectName: "wisdomTooth",
        };
      }
      if (item?.erupted?.geometry) {
        const calculateRatio = item.ratio;
        let impactedGeometery = item?.erupted?.geometry || [];
        let impactedGeometeryType = item?.erupted.geometry_type || "polygon";
        let d = {
          id: item.label,
          tooth_number: item.label,
          geometry: impactedGeometery,
          geometry_type: impactedGeometeryType,
          metaclass_type: "Tooth and Impaction Level",
          color: LABEL_COLORS.ImplantErupted,
        };
        polygons[`Tooth #${d.tooth_number} Erupted ${calculateRatio} %`] = {
          ...d,
          parentObjectName: "wisdomTooth",
        };
      }
    });
  }
  if (!findingRules) {
    return polygons;
  } else {
    let finalPolygons = {};
    findingRules.allowFindings.forEach((finding) => {
      if (polygons[finding]) {
        finalPolygons = { ...finalPolygons, [finding]: polygons[finding] };
      }
    });
    return finalPolygons;
  }
}

/**
 * Util function will be used to determince color code according to review result.
 *
 * @param {string} RGB color code
 */
export function getColorMatchedWithResult(reviewResult, polygonType) {
  if (polygonType) {
    return LABEL_COLORS[polygonType];
  }
  let color;
  switch (reviewResult) {
    case "D":
      color = "255, 134, 132";
      break;
    case "CR":
      color = "180, 152, 255";
      break;
    case "A":
      color = "168, 236, 171";
      break;
    case "PD":
      color = "241, 222, 174";
      break;
    case "P":
      color = "224, 175, 158";
      break;
    case "R":
      color = "128,198,255";
      break;
    default:
      color = "244, 166, 35";
  }
  return color;
}

export function getColorFromClaimCategory(claimCategory) {
  let color;
  switch (claimCategory) {
    case "Clinical Review": {
      color = "120, 144, 156";
      break;
    }
    case "Documentation Request": {
      color = "141, 110, 99";
      break;
    }
    case "Approval Processing": {
      color = "168, 236, 171";
      break;
    }
    default: {
      color = "120, 144, 156";
      break;
    }
  }
  return color;
}

export function getClinicallyReviewedColor(reviewResult) {
  let color;
  switch (reviewResult) {
    case "CR": {
      color = COLORS.RED;
      break;
    }

    case "Deny": {
      color = COLORS.RED;
      break;
    }

    case "Accept": {
      color = COLORS.GREEN;
      break;
    }

    case "Review": {
      color = COLORS.BLUE;
      break;
    }

    case "Pend": {
      color = COLORS.BROWN;
      break;
    }

    case "Pd": {
      color = COLORS.BLUEGRAY;
      break;
    }

    default:
      color = COLORS.BLUE;
      break;
  }
  return color;
}

/**
 * Convert result code to result word
 * @param result
 * @return {any|string}
 */
export function getReviewResult(result) {
  switch (result) {
    case "A":
      return "Accept";
    case "D":
      return "Deny";
    case "CR":
      return "Clinical Review";
    case "R":
      return "Review";
    case "P":
      return "Pend";
    case "Pd":
      return "Partial Deny";
    default:
      return result;
  }
}

/**
 * This function will take Image width as input and return padding top in pixel
 * Used to prevant image boundry touch of line label
 * @param {number} imageWidth
 */
export function getPaddingTop(imageWidth) {
  if (imageWidth < 500) return 5;
  else if (imageWidth >= 500 && imageWidth <= 1000) return 10;
  else return 18;
}

/**
 * This function will take Image width as input and Distance in pixel for Distal and Meisal
 * Used to draw label on teeth (insteat of sides).
 * This function will help to adjust position of label that draw on LR and UR Quadrant
 * @param {number} imageWidth
 */
export function zoomToPixelRightQuadrant(imageWidth) {
  switch (true) {
    case imageWidth < 100:
      return { Single: 5, Double: 6 };
    case imageWidth >= 100 && imageWidth < 125:
      return { Single: 8, Double: 10 };
    case imageWidth >= 125 && imageWidth < 150:
      return { Single: 8, Double: 10 };
    case imageWidth >= 150 && imageWidth < 175:
      return { Single: 8, Double: 10 };
    case imageWidth >= 175 && imageWidth < 200:
      return { Single: 8, Double: 10 };
    case imageWidth >= 200 && imageWidth < 225:
      return { Single: 8, Double: 8 };
    case imageWidth >= 225 && imageWidth < 250:
      return { Single: 8, Double: 10 };
    case imageWidth >= 250 && imageWidth < 275:
      return { Single: 8, Double: 16 };
    case imageWidth >= 275 && imageWidth < 300:
      return { Single: 8, Double: 16 };
    case imageWidth >= 300 && imageWidth < 325:
      return { Single: 9, Double: 17 };
    case imageWidth >= 325 && imageWidth < 350:
      return { Single: 9, Double: 17 };
    case imageWidth >= 350 && imageWidth < 375:
      return { Single: 9, Double: 17 };
    case imageWidth >= 375 && imageWidth < 400:
      return { Single: 11, Double: 19 };
    case imageWidth >= 400 && imageWidth < 425:
      return { Single: 11, Double: 19 };
    case imageWidth >= 425 && imageWidth < 450:
      return { Single: 11, Double: 19 };
    case imageWidth >= 450 && imageWidth < 475:
      return { Single: 11, Double: 19 };
    case imageWidth >= 475 && imageWidth < 500:
      return { Single: 13, Double: 21 };
    case imageWidth >= 500 && imageWidth < 525:
      return { Single: 13, Double: 21 };
    case imageWidth >= 525 && imageWidth < 550:
      return { Single: 13, Double: 21 };
    case imageWidth >= 550 && imageWidth < 575:
      return { Single: 13, Double: 21 };
    case imageWidth >= 575 && imageWidth < 600:
      return { Single: 15, Double: 23 };
    case imageWidth >= 600 && imageWidth < 625:
      return { Single: 15, Double: 23 };
    case imageWidth >= 625 && imageWidth < 650:
      return { Single: 15, Double: 23 };
    case imageWidth >= 650 && imageWidth < 675:
      return { Single: 15, Double: 23 };
    case imageWidth >= 675 && imageWidth < 700:
      return { Single: 17, Double: 25 };
    case imageWidth >= 700 && imageWidth < 725:
      return { Single: 17, Double: 25 };
    case imageWidth >= 725 && imageWidth < 750:
      return { Single: 17, Double: 25 };
    case imageWidth >= 750 && imageWidth < 775:
      return { Single: 17, Double: 25 };
    case imageWidth >= 775 && imageWidth < 800:
      return { Single: 19, Double: 27 };
    case imageWidth >= 800 && imageWidth < 825:
      return { Single: 19, Double: 27 };
    case imageWidth >= 825 && imageWidth < 850:
      return { Single: 19, Double: 27 };
    case imageWidth >= 850 && imageWidth < 875:
      return { Single: 19, Double: 27 };
    case imageWidth >= 875 && imageWidth < 900:
      return { Single: 21, Double: 29 };
    case imageWidth >= 900 && imageWidth < 925:
      return { Single: 21, Double: 29 };
    case imageWidth >= 925 && imageWidth < 950:
      return { Single: 45, Double: 60 };
    case imageWidth >= 950 && imageWidth < 975:
      return { Single: 55, Double: 50 };
    case imageWidth >= 975 && imageWidth < 1000:
      return { Single: 30, Double: 60 };
    case imageWidth >= 1000:
      return { Single: 30, Double: 50 };
    default:
      return { Single: 5, Double: 7 };
  }
}

/**
 * This function will take Image width as input and Distance in pixel for Distal and Meisal
 * Used to draw label on teeth (insteat of sides).
 * This function will help to adjust position of label that draw on LL and UL Quadrant
 * @param {number} imageWidth
 */
export function zoomToPixelLeftQuadrant(imageWidth) {
  switch (true) {
    case imageWidth < 100:
      return { Single: 6, Double: 10 };
    case imageWidth >= 100 && imageWidth < 125:
      return { Single: 7, Double: 7 };
    case imageWidth >= 125 && imageWidth < 150:
      return { Single: 9, Double: 11 };
    case imageWidth >= 150 && imageWidth < 175:
      return { Single: 7, Double: 7 };
    case imageWidth >= 175 && imageWidth < 200:
      return { Single: 7, Double: 10 };
    case imageWidth >= 200 && imageWidth < 225:
      return { Single: 7, Double: 7 };
    case imageWidth >= 225 && imageWidth < 250:
      return { Single: 7, Double: 8 };
    case imageWidth >= 250 && imageWidth < 275:
      return { Single: 8, Double: 15 };
    case imageWidth >= 275 && imageWidth < 300:
      return { Single: 16, Double: 15 };
    case imageWidth >= 300 && imageWidth < 325:
      return { Single: 18, Double: 17 };
    case imageWidth >= 325 && imageWidth < 350:
      return { Single: 18, Double: 17 };
    case imageWidth >= 350 && imageWidth < 375:
      return { Single: 18, Double: 17 };
    case imageWidth >= 375 && imageWidth < 400:
      return { Single: 20, Double: 19 };
    case imageWidth >= 400 && imageWidth < 425:
      return { Single: 20, Double: 19 };
    case imageWidth >= 425 && imageWidth < 450:
      return { Single: 20, Double: 19 };
    case imageWidth >= 450 && imageWidth < 475:
      return { Single: 20, Double: 19 };
    case imageWidth >= 475 && imageWidth < 500:
      return { Single: 22, Double: 21 };
    case imageWidth >= 500 && imageWidth < 525:
      return { Single: 22, Double: 21 };
    case imageWidth >= 525 && imageWidth < 550:
      return { Single: 22, Double: 21 };
    case imageWidth >= 550 && imageWidth < 575:
      return { Single: 24, Double: 21 };
    case imageWidth >= 575 && imageWidth < 600:
      return { Single: 24, Double: 23 };
    case imageWidth >= 600 && imageWidth < 625:
      return { Single: 24, Double: 23 };
    case imageWidth >= 625 && imageWidth < 650:
      return { Single: 24, Double: 23 };
    case imageWidth >= 650 && imageWidth < 675:
      return { Single: 24, Double: 23 };
    case imageWidth >= 675 && imageWidth < 700:
      return { Single: 26, Double: 25 };
    case imageWidth >= 700 && imageWidth < 725:
      return { Single: 26, Double: 25 };
    case imageWidth >= 725 && imageWidth < 750:
      return { Single: 26, Double: 25 };
    case imageWidth >= 750 && imageWidth < 775:
      return { Single: 26, Double: 25 };
    case imageWidth >= 775 && imageWidth < 800:
      return { Single: 28, Double: 27 };
    case imageWidth >= 800 && imageWidth < 825:
      return { Single: 30, Double: 60 };
    case imageWidth >= 825 && imageWidth < 850:
      return { Single: 30, Double: 50 };
    case imageWidth >= 850 && imageWidth < 875:
      return { Single: 30, Double: 60 };
    case imageWidth >= 875 && imageWidth < 900:
      return { Single: 30, Double: 60 };
    case imageWidth >= 900 && imageWidth < 925:
      return { Single: 30, Double: 60 };
    case imageWidth >= 925 && imageWidth < 950:
      return { Single: 30, Double: 60 };
    case imageWidth >= 950 && imageWidth < 975:
      return { Single: 30, Double: 50 };
    case imageWidth >= 975 && imageWidth < 1000:
      return { Single: 30, Double: 60 };
    case imageWidth >= 1000:
      return { Single: 30, Double: 50 };
    default:
      return { Single: 6, Double: 11 };
  }
}

export function isAccessToNavButtons() {
  const { currentClaimIndex, totalClaims, fmxRedirect } = getURLSearchParams();
  return (currentClaimIndex && totalClaims) || fmxRedirect ? true : false;
}

export function getURLSearchParams() {
  let params = new URLSearchParams(window.location.search);
  return {
    claimId: params.get("claimId"),
    currentClaimIndex: params.get("claimIndex"),
    totalClaims: params.get("totalClaims"),
    fmxRedirect: params.get("fmxRedirect"),
    returnsTo: params.get("returnsTo"),
  };
}

export const isToothBasedProcedure = (pCode) => {
  if (
    pCode &&
    (pCode.startsWith("D2") ||
      pCode.startsWith("2") ||
      pCode.startsWith("D62") ||
      pCode.startsWith("D67") ||
      pCode.startsWith("D3") ||
      pCode.startsWith("D65") ||
      IMPLANTS_PROCEDURES.includes(pCode))
  ) {
    return true;
  }
  return toothBasedProcedure.includes(pCode);
};

export const noToothNoQuadBasedProcedure = (pCode) => {
  if (pCode && pCode.startsWith("D4910")) {
    return true;
  }
  return false;
};
