export const API_URL = process.env.REACT_APP_INSURANCE_APP_API_URL;
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URI;
export const FIREBASE = {
  API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  DB_URL: process.env.REACT_APP_FIREBASE_DB_URL,
  MSG_SENDING_ID: process.env.REACT_APP_FIREBASE_MSG_SENDING_ID,
  PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};
