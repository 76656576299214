import { applyMiddleware, combineReducers, compose, createStore } from "redux";

import { createBrowserHistory } from "history";
import reducers from "../redux/reducers";
import thunk from "redux-thunk";

const history = createBrowserHistory();
const middlewares = [thunk];

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const store = createStore(
  combineReducers({
    ...reducers,
  }),
  composeEnhancers(applyMiddleware(...middlewares))
);
// sagaMiddleware.run(rootSaga);
export { store, history };
