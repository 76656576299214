import { IClaimsCardProps } from "src/defs/claims";
import getLabel from "src/components/canvas/labelMapping";
import { UserInformation } from "src/services/types";
import { GenericObject } from "src/defs";

import { v4 as uuid } from "uuid";
import { isArray, isEmpty, isObject } from "lodash";
import moment, { Moment } from "moment";

export interface IClaimsRolledup {
  total: IClaimsCardProps;
  reviewed: IClaimsCardProps;
  reviewResult: IClaimsCardProps;
  savings: IClaimsCardProps;
}

const getLocaleString: Function = (
  val: number | string,
  decimal: number | undefined
): string => {
  if (!val) return "";
  if (!decimal) return (+val).toLocaleString();
  return (+parseFloat(`${val}`).toFixed(decimal)).toLocaleString();
};

export function claimsAnalyticsRollup(
  analytics: any = {},
  loading: boolean,
  error: any
): IClaimsRolledup {
  const rolledupClaimsAnalytics: any = {};
  const statsProps = {
    borderColor: "",
    chartId: "",
    heading: "",
    name: "",
    value: [] as any,
    valuePrefix: "",
    valuePostfix: "",
  };

  const savings = {
    loading,
    error,
    data: {
      ...statsProps,
      borderColor: "#4CAF50",
      heading: "Savings",
      loading: loading,
      name: "savings",
      value: [
        {
          key: "",
          value: analytics.savings
            ? `$${getLocaleString(Number(Math.round(analytics.savings)))}`
            : 0,
        },
      ],
    },
  };

  const claims = {
    loading,
    error,
    data: {
      ...statsProps,
      borderColor: "#03A9F4",
      heading: "Unique Claims",
      name: "claims",
      value: [
        {
          key: "Submitted",
          value:
            analytics.uniqueClaimsSubmitted === 0
              ? 0
              : getLocaleString(analytics.uniqueClaimsSubmitted),
        },
        {
          key: "Reviewed",
          value:
            analytics.uniqueClaimsReviewed === 0
              ? 0
              : getLocaleString(analytics.uniqueClaimsReviewed),
        },
      ],
    },
  };

  const quadrants = {
    loading,
    error,
    data: {
      ...statsProps,
      borderColor: "#673ab7",
      heading: "Total Submitted Review",
      loading: loading,
      name: "quadrants",
      value: [
        {
          key: "Submitted",
          value:
            analytics.totalQuadrantsSubmitted === 0
              ? 0
              : getLocaleString(analytics.totalQuadrantsSubmitted),
        },
        {
          key: "Reviewed",
          value:
            analytics.totalQuadrantsReviewed === 0
              ? 0
              : getLocaleString(analytics.totalQuadrantsReviewed),
        },
      ],
    },
  };

  const reviewResult = {
    loading,
    error,
    data: {
      ...statsProps,
      heading: "Review Result",
      borderColor: "#F44336",
      name: "reviewResult",
    },
  };

  if (analytics.statsByReviewResult) {
    const { statsByReviewResult } = analytics;
    if (isEmpty(statsByReviewResult))
      reviewResult.data.value.push({ key: "", value: 0 });
    Object.keys(statsByReviewResult).forEach((item) => {
      const total = Math.round(
        (statsByReviewResult[item] / analytics.totalQuadrantsReviewed) * 100
      );
      reviewResult.data.value.push({
        key: item,
        value: `${total}%`,
      });
    });
  }

  rolledupClaimsAnalytics.quadrants = quadrants;
  rolledupClaimsAnalytics.claims = claims;
  rolledupClaimsAnalytics.reviewResult = reviewResult;
  rolledupClaimsAnalytics.savings = savings;
  return rolledupClaimsAnalytics;
}

export function claimsTableRollup(
  headers: string[],
  claims: any[],
  separator: string
) {
  // Create the header of the csv file
  let csv: string = headers.join(separator);

  // Iterate over all of the objects and append the appropriate values as a row in the csv
  for (const claim of Object.values(claims)) {
    csv += "\n";

    let row: string[] = headers.map((key) => {
      let entry = null;

      // Using a switch just in case we need to add any more special cases
      switch (key) {
        case "overjet review results":
          entry = claim.overjetReviewResult;
          break;
        default:
          entry = claim.record[key];
      }

      return entry;
    });

    // Wrap each string entry in row with quotes if it contains a comma
    row = row.map((entry: string) => {
      // ! It could've been a default arg assignment but that does not take null into consideration. 😕
      entry = entry || "";
      return entry.includes(separator) ? JSON.stringify(entry) : entry;
    });

    // Append the new row, joined together by commas, to the csv string
    csv += row.join(separator);
  }

  return csv;
}

/**
 * @description - Normalize polygons (line, box) in a single data strcutre
 */
export function transformPolygonObject(
  data: any,
  key: any,
  toothNumber: any,
  geometry: any
) {
  const uid = uuid();
  return {
    add_finding: false,
    color: data[key].color,
    delete_finding: false,
    deleted: false,
    geometry: [geometry],
    geometry_labels: data[key].geometry_labels,
    hidden: false,
    id: data[key].id,
    key: getLabel(key),
    metaclass_type: getLabel(data[key].metaclass_type),
    parentObjectName: data[key].parentObjectName,
    polygonType: data[key].geometry_type,
    subType: getLabel(data[key].metaclass_sub_type),
    toothNumber: toothNumber ? [parseInt(toothNumber) + ""] : "",
    type: getLabel(data[key].class_type),
    uuid: uid,
  };
}

/**
 * Converts string date to Moment object
 *
 * @param str The target string
 * @returns Moment
 */
export function strToMoment(str: string | Moment): Moment {
  if (str instanceof String) {
    const [yyyy, mm, dd] = str.split("-");
    return moment(new Date(Number(yyyy), Number(mm) - 1, Number(dd)));
  }
  return moment(str);
}

/**
 * Converts camelCase string to sentance case
 * @param str The string to be converted
 * @returns The sentance case string
 */
export function camelCaseToSentence(str: string) {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

/**
 * Combine sentence into camelCase word
 * @param str The target string
 * @returns String
 */
export function sentenceToCamelCase(str: string = ""): string {
  if (str.length === 0) return str;
  return str.replace(/\s+(.)/g, function (match, group) {
    return group.toUpperCase();
  });
}

/**
 * Truncate string to number of characters and add ellipsis at the end
 *
 * @param str The target string
 * @param n Number of characters to truncate
 * @param useWordBoundary To allow words break
 * @returns
 */

export function truncateString(
  str: string,
  n: number,
  useWordBoundary: boolean
): string {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substring(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.substring(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
}

/**
 * Remove last enter key from the string
 * @param str The input string
 * @returns The string free from enter key
 */
export function removeLastEnterKey(str: string): string {
  if (str.length === 0) return str;
  return str.lastIndexOf("\n") === str.length - 1
    ? removeLastEnterKey(str.slice(0, str.length - 1))
    : str;
}

/**
 * Filters the input comment
 * @param {string} str - The input string
 * @returns The filtered input comments
 */

export const filterInputComment = (str: string): string => {
  return removeLastEnterKey(str || "")
    .replace(/\n/g, "<br />")
    .replace(/\\/g, "%slash%")
    .replace(/"/g, "%quotes%");
};

/**
 * Extract the file name from image source string
 * @param {string} str - The source string
 * @returns
 */
export const extractFileNameFromSource = (str?: string): string | null => {
  if (str) {
    let output: any = str.split(".jpg");
    if (!output) {
      output = str.split(".jpeg");
    }
    return output.shift()?.split("/").pop();
  }
  return null;
};

/**
 * Filters the comment text
 * @param {string} str - The input string
 * @returns The filtered comment text
 */

export const filterCommentText = (str: string = ""): string => {
  return str
    .replace(/<br \/>/g, "\n")
    .replace(/%slash%/g, "\\")
    .replace(/%quotes%/g, '"');
};

/**
 * Clear __typename from the underlying object at deep level
 * @param obj Generic object
 * @returns
 */

export function clearTypeNameKey<T extends GenericObject>(obj?: T): T | null {
  if (!obj) return null;
  const arrayOfValues: Array<string> =
    typeof obj === "object" && !Array.isArray(obj)
      ? Object.keys(obj)
      : Object.values(obj);
  arrayOfValues.forEach((k) => {
    if ((isObject(obj[k]) || isObject(k)) && typeof obj.type !== "string") {
      return clearTypeNameKey(obj[k] || k);
    }
    if (k === "__typename") {
      delete obj.__typename;
    }
  });
  return obj;
}

/**
 * Convert string to underscored string
 *
 * @param str The string to be changed
 * @returns The underscored string
 */

export function strToUnderscored(str: string): string {
  return str.replace(/\s/g, "_");
}

/**
 * Get user full name
 *
 * @param userInfo User object
 * @returns user full name
 */

export function getFullName(userInfo?: UserInformation) {
  const name: string[] = [];
  if (userInfo?.firstName) {
    name.push(userInfo?.firstName);
  }
  if (userInfo?.lastName) {
    name.push(userInfo?.lastName);
  }

  return name.join(" ");
}
/**
 * Capitalize first letter of a string
 * @param string
 * @returns string
 */
export function capitalizeFirstLetter(string: string | undefined): string {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

/**
 * A custom filter function which flatten the nested object to single layer object
 * @param obj The original object
 * @returns The new flatten object
 */
export function flatObject(obj: GenericObject) {
  if (obj) {
    const step = function (o: GenericObject, t: GenericObject) {
      for (const key in o) {
        if (isObject(o[key]) && !isArray(o[key])) {
          step(o[key], t);
        } else {
          t[key] = o[key];
        }
      }
    };
    const target = {};
    step(obj, target);
    return target;
  }
  return obj;
}
