export const LABEL_COLORS: { [key: string]: string } = {
  Decay: "255, 134, 132",
  Missing: "250, 195, 115",
  "Open Margin": "129,212,250",
  "Root Canal Treatment": "179,157,219",
  RootCanal: "248, 169, 255",
  Calculus: "203, 242, 164",
  Caries: "255,224,130",
  Crown: "102, 255, 255",
  Posts: "179,157,219",
  Restoration: "143, 216, 203",
  Pontic: "255, 174, 223",
  Retainer: "160, 223, 255",
  Implant: "248, 169, 255",
  Parl: "253, 255, 168",
  BoneLevelCriteriaMet: "221, 53, 201",
  BoneLevelCriteriaNotMet: "78, 254, 255",
  BoneRatioCriteriaMet: "221, 53, 201",
  BoneRatioCriteriaNotMet: "78, 254, 255",
  CalculusCriteriaMet: "255, 164, 64",
  ImplantImpacted: "78, 254, 255",
  ImplantErupted: "253, 255, 168",
  "Attrition/Erosion": "255, 127, 80",
  Perio: "184,233,134",
  Furcation: "184, 233, 134",
  Tooth_Color: "221, 53, 201",
  ClaimedTooth: "168, 236, 171",
};

export const ORTHO_COLORS: { [key: string]: string } = {
  SPACING_OPEN: "#F4FFB8",
  SPACING_CLOSED: "#FF7875",
  ANTERIOR_CROWDING: "#FADB14",
  FULL_ARCH_CROWDING: "#FFCCC7",
  LABIO_LINGUAL_SPREAD: "#69C0FF",
  ECTOPIC: "#D9F7BE",
  CROWDED: "#FFCCC7",
  ROTATED: "#FF85C0",
  OVERBITE: "#9254DE",
  OVERJET: "#B5F5EC",
  REVERSE_OVERJET: "#36CFC9",
  CROSSBITE: "#FFE7BA",
  IMPACTED: "#FAAD14",
  OPENBITE: "#95DE64",
  ANTERIOR_POSTERIOR_DISCREPANCY: "#BAE7FF",
};
export const LABEL_SORT_ORDER: { [x: string]: number } = {
  Restorative: 1,
  Caries: 2,
  "Open Margin": 3,
  Findings: 4,
  Calculus: 5,
  "Bone Loss": 6,
  Visualization: 7,
  "Bone Level": 8,
  "Existing Restorations": 9,
  "Root Canal Treatment": 10,
  Restoration: 11,
  Posts: 12,
  Crown: 13,
  "Acceptance Criteria Met": 15,
  "Acceptance Criteria Not Met": 16,
};
export const FINDING_ANNOTATIONS_SORT_ORDER: Record<string, number> = {
  Decay: 1,
  Missing: 2,
  "Root Canal": 3,
  PARL: 4,
  Crown: 5,
  "Restoration/Filling": 6,
  Pontic: 7,
  Retainer: 8,
  Implant: 9,
  "Tooth Impacted": 10,
  "Tooth Erupted": 11,
  "Bone Level": 12,
  "Bone Ratio": 13,
  Calculus: 14,
};
