import { IClaims } from "src/defs";
import { CacheKeys } from "src/constants/cache-keys";
import cacheService, { CacheService } from "./cache.service";
import {
  ClaimsTableConfig,
  ClaimsTableConfigReset,
} from "src/configs/components-config";
import { _ } from "src/shared/libs";
import { sentenceToCamelCase } from "src/utils/transformers";
import { UserProfile } from "./types";

export class QueueTableService {
  private _config: IClaims.IClaimsQueryConfig = this.getDefaultConfig();
  private _selectedRowId: string | undefined = undefined;
  private _visibleColsV1: IClaims.IVisibleColsDictionary = [] as any;
  private _visibleColsV2: IClaims.IVisibleColsDictionary = [] as any;

  constructor(private _cacheService: InstanceType<typeof CacheService>) {
    const config = _cacheService.getItemsSync(CacheKeys.QueueTableConfig);
    if (config) this.setConfig(config, false);
  }

  processColumns(clientConfig: any, version = 1) {
    let mapped: Array<IClaims.IVisibleColsDictionary> | Array<any>;
    const keyName = version > 1 ? "claimLevelQueueConfig" : "queueConfig";
    if (clientConfig && clientConfig[keyName]) {
      mapped = clientConfig[keyName].queueHeadersConfig;
    } else {
      mapped = (clientConfig?.queueConfig?.queueHeaders || []).map(
        (colName: string) => ({
          colName,
          checked: true,
          displayName: colName,
        })
      );
    }

    return _.keyBy(mapped, (o: any) => {
      return sentenceToCamelCase(o.colName);
    });
  }

  setVisibleColumnsFromCache() {
    if (Object.keys(this._visibleColsV1).length) return this._visibleColsV1;
    if (Object.keys(this._visibleColsV2).length) return this._visibleColsV2;

    const userProfile: UserProfile = this._cacheService.getItemsSync(
      CacheKeys.UserProfile
    );
    if (!userProfile) return [];

    this._visibleColsV1 = this.processColumns(userProfile.clientConfig);
    this._visibleColsV2 = this.processColumns(userProfile.clientConfig, 2);
  }

  getDefaultConfig() {
    return {
      search: ClaimsTableConfig.default.search,
      procedureType: ClaimsTableConfig.default.procedureType,
      specialtyReviewFilter: ClaimsTableConfig.default.specialtyReviewFilter,
      page: {
        offset: ClaimsTableConfig.default.pageOffset,
        size: ClaimsTableConfig.default.pageSize,
      },
      systemFilter: ClaimsTableConfig.default.systemFilter,
    };
  }

  getResetDefaultConfig() {
    return {
      search: ClaimsTableConfigReset.default.search,
      procedureType: ClaimsTableConfigReset.default.procedureType,
      page: {
        offset: ClaimsTableConfigReset.default.pageOffset,
        size: ClaimsTableConfigReset.default.pageSize,
      },
      ingressFilter: ClaimsTableConfigReset.default.ingressFilter,
      stateFilter: ClaimsTableConfigReset.default.stateFilter,
      systemFilter: ClaimsTableConfigReset.default.systemFilter,
      specialtyReviewFilter:
        ClaimsTableConfigReset.default.specialtyReviewFilter,
    };
  }
  setConfig(config: IClaims.IClaimsQueryConfig, persistant = false) {
    this._config = config;
    if (persistant) {
      this._cacheService.setItemSync(CacheKeys.QueueTableConfig, config);
    }
  }
  getConfig() {
    return this.getDefaultConfig();
  }
  getSelectedRowId() {
    return this._selectedRowId;
  }
  setSelectedRowId(id: string) {
    const rowSelect = (id: string) =>
      document.querySelectorAll(`table tr[data-row-key="${id}"]`);
    if (this._selectedRowId)
      rowSelect(this._selectedRowId).forEach((ele) =>
        ele.removeAttribute("id")
      );

    rowSelect(id).forEach((ele) => ele.setAttribute("id", "ia-selected-row"));
    this._selectedRowId = id;
  }
  crawlToSelectedRow() {
    const rowId = this.getSelectedRowId();
    const element = document.querySelectorAll(
      `table tr[data-row-key="${rowId}"]`
    );
    if (!element) return;

    element.forEach((e) => {
      e.setAttribute("id", "ia-selected-row");
      e.scrollIntoView({ block: "center" });
    });
  }

  setVisibleCols(cols: IClaims.IVisibleColsDictionary) {
    this._visibleColsV1 = _.keyBy(cols, (o: any) => {
      return sentenceToCamelCase(o.colName);
    });
  }

  getVisibleCols(version = 1) {
    if (version === 1) return this._visibleColsV1;
    return this._visibleColsV2;
  }

  flush() {
    this.setVisibleCols([] as any);
    this.setSelectedRowId("");
    this.setConfig(this.getDefaultConfig());
  }
}

const queueTableService = new QueueTableService(cacheService);
export default queueTableService;
