import { KeyboardKeys } from "src/components/new-design/utility/constants";
import { ServiceLineObject } from "src/context/canvas/types";
import { QUADRANT_RANGE } from "src/constants";
import moment from "moment";

export const dispatchCustomEvent = (eventName: string, data?: any) => {
  const customEvent = new CustomEvent(eventName, {
    bubbles: true,
    cancelable: true,
    composed: true,
    detail: data,
  });
  document.dispatchEvent(customEvent);
};

export const isCanvasOrFMXPage = (pathname: string) => {
  const path = pathname.split("/").pop();
  return !!(path && (path === "canvas" || path === "fmx"));
};

export const toothNumberToQuadrant = (tooth: string | Number) => {
  let toothNumber = Number(tooth);
  let quadrant = "";
  for (const [key, [lower, upper]] of Object.entries(QUADRANT_RANGE)) {
    if (toothNumber >= lower && toothNumber <= upper) {
      quadrant = key;
    }
  }
  return quadrant;
};

export const walkThroughElements = (
  list: any,
  counter: { index: number; selected: any },
  event: KeyboardEvent,
  addCallback: (selectedElement: any) => void,
  removeCallback?: (selectedElement: any) => void,
  startIndex?: number
) => {
  if (
    event?.key === KeyboardKeys.ARROW_DOWN ||
    event?.key === KeyboardKeys.ARROW_RIGHT ||
    event?.key === "s"
  ) {
    counter.index++;
    if (counter.selected) {
      if (removeCallback) removeCallback(counter.selected);
      const next = list[counter.index];
      if (typeof next !== undefined && counter.index <= list.length - 1) {
        counter.selected = next;
      } else {
        counter.index = 0;
        counter.selected = list[0];
      }
      addCallback(counter.selected);
    } else {
      counter.index = startIndex || 0;
      counter.selected = list[startIndex || 0];
      addCallback(counter.selected);
    }
  } else if (
    event?.key === KeyboardKeys.ARROW_UP ||
    event?.key === KeyboardKeys.ARROW_LEFT ||
    event?.key === "w"
  ) {
    if (counter.selected) {
      if (removeCallback) removeCallback(counter.selected);
      counter.index--;
      const next = list[counter.index];
      if (typeof next !== undefined && counter.index >= 0) {
        counter.selected = next;
      } else {
        counter.index = list.length - 1;
        counter.selected = list[counter.index];
      }
      addCallback(counter.selected);
    } else {
      counter.index = 0;
      counter.selected = list[list.length - 1];
      addCallback(counter.selected);
    }
  }
};

export const walkThroughGalleryElements = (
  list: any,
  counter: { index: number; selected: any },
  event: KeyboardEvent,
  addCallback: (selectedElement: any) => void,
  removeCallback?: (selectedElement: any) => void,
  startIndex?: number,
  relatedImage?: any,
  allAttachmentsImage?: any
) => {
  if (event?.key === KeyboardKeys.ARROW_DOWN) {
    if (counter.selected) {
      if (relatedImage.length % 2 === 0) {
        if (
          counter.index === list.length - 2 &&
          allAttachmentsImage.length % 2 !== 0
        ) {
          // This will true if all attachment section has odd number of images
          // And selection is on 2nd last image.
          counter.index++;
        } else {
          counter.index += 2;
        }
      } else {
        if (counter.index <= 0 && relatedImage.length === 1) {
          counter.index = 1;
        } else if (counter.index <= 0) {
          counter.index = 2;
        } else if (
          counter.index === relatedImage.length - 2 ||
          counter.index === relatedImage.length - 1 ||
          counter.index === list.length - 2
        ) {
          counter.index++;
        } else {
          counter.index += 2;
        }
      }
      counter.selected = list[counter.index];
      if (removeCallback) removeCallback(counter.selected);
      const next = list[counter.index];
      if (typeof next !== undefined && counter.index <= list.length - 1) {
        counter.selected = next;
      } else {
        counter.index = 0;
        counter.selected = list[0];
      }
      addCallback(counter.selected);
    } else {
      if (relatedImage.length % 2 === 0) {
        if (counter.index === -1) {
          counter.index = 2;
        } else {
          counter.index += 2;
        }
        counter.selected = list[counter.index];
      } else {
        if (counter.index <= 0 && relatedImage.length === 1) {
          counter.index = 1;
          counter.selected = list[counter.index];
        } else if (counter.index === -1) {
          counter.index = 2;
          counter.selected = list[counter.index];
        } else {
          counter.index = counter.index <= 0 ? 1 : counter.index + 2;
          counter.selected = list[counter.index];
        }
      }
      addCallback(counter.selected);
    }
  } else if (event?.key === KeyboardKeys.ARROW_UP) {
    if (counter.index <= 0) {
      counter.index = list.length - 1;
      counter.selected = list[counter.index];
      addCallback(counter.selected);
    } else if (counter.index === 1 && relatedImage.length !== 1) {
      counter.selected = list[counter.index];
      addCallback(counter.selected);
    } else {
      if (relatedImage.length % 2 === 0) {
        counter.index = counter.index - 2;
      } else {
        if (counter.index === relatedImage.length) {
          counter.index = counter.index - 1;
        } else {
          counter.index = counter.index - 2;
        }
      }
      if (counter.selected) {
        if (removeCallback) removeCallback(counter.selected);
      }
      counter.selected = list[counter.index];

      addCallback(counter.selected);
    }
  } else if (event?.key === KeyboardKeys.ARROW_RIGHT) {
    counter.index++;
    if (counter.selected) {
      if (removeCallback) removeCallback(counter.selected);
      const next = list[counter.index];
      if (typeof next !== undefined && counter.index <= list.length - 1) {
        counter.selected = next;
      } else {
        counter.index = 0;
        counter.selected = list[0];
      }
      addCallback(counter.selected);
    } else {
      counter.index = startIndex || 0;
      counter.selected = list[startIndex || 0];
      addCallback(counter.selected);
    }
  } else if (event?.key === KeyboardKeys.ARROW_LEFT) {
    if (counter.selected) {
      if (removeCallback) removeCallback(counter.selected);
      counter.index--;
      const next = list[counter.index];
      if (typeof next !== undefined && counter.index >= 0) {
        counter.selected = next;
      } else {
        counter.index = list.length - 1;
        counter.selected = list[counter.index];
      }
      addCallback(counter.selected);
    } else {
      counter.index = 0;
      counter.selected = list[list.length - 1];
      addCallback(counter.selected);
    }
  }
};

export const procedureQuadrantSort: any = (
  serviceLineList: Array<ServiceLineObject>
) => {
  var sortPattern = [
    "UR",
    "UL",
    "LL",
    "LR",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
  ];
  let sortedServiceLineList: Array<ServiceLineObject> = [];

  sortPattern.forEach((item) => {
    let filtered = serviceLineList.filter(
      (el: ServiceLineObject) => el.toothOrQuad === item
    );
    if (!filtered) return;
    else if (filtered.length === 1) {
      sortedServiceLineList = [...sortedServiceLineList, ...filtered];
    } else {
      filtered.forEach((filterItem: ServiceLineObject) =>
        sortedServiceLineList.push(filterItem)
      );
    }
  });

  return sortedServiceLineList;
};

export const formatDate = (currentDate: string | undefined) => {
  return currentDate && moment(currentDate, true).isValid()
    ? moment(currentDate).format("MM/DD/YYYY")
    : undefined;
};
