import { GenericContext, GenericObject } from "src/defs";
import {
  CanvasInformationObject,
  FindingI,
  ImagePredictionI,
  ServiceLineObject,
} from "src/context/canvas/types";

export type OrthoState = {
  error?: GenericObject;
  claimLoading: boolean;
  claimLoaded: boolean;
  claimInformation?: OrthoClaimInformation;
  imagePredictionLoading: boolean;
  imagePredictionLoaded: boolean;
  orthoImagePredictions: OrthoImagePredictions;
  allImages?: OrthoImageObj;
  sortedAllTemplateImages?: string[];
  showIntraImages?: boolean;
  showNonIntraImages?: boolean;
  templateIntraImages?: string[][];
  templateNonIntraImages?: string[];
  additionalImages?: string[];
  imagesQueue?: string[];
};

interface Point {
  x: number;
  y: number;
}

export type OrthoAction = {
  type: string;
  payload?: any;
};

export interface OrthoContextI extends GenericContext {
  state: OrthoState;
}

export type OrthoClaimInformation = {
  id?: string;
  claimId?: string;
  patientId?: string;
  activeServiceLine?: ServiceLineObject;
  processingState?: string;
  dateOfService?: string;
  ruleExecutionId?: string;
  supportedServiceLines: ServiceLineObject[];
  unSupportedServiceLines: ServiceLineObject[];
  orthoFindings: OrthoFindings;
};

export interface OrthoCanvasInformationI {
  claimLevelCanvasInformation: OrthoCanvasInformationObject;
}

export interface OrthoClaimPredictionI {
  claimLevelCanvasPrediction: OrthoCanvasPredictionObject;
}

export interface OrthoCanvasPredictionObject {
  claimPrediction: OrthoClaimPrediction;
  imagePredictions: OrthoImagePredictionObject;
  ingressType?: string;
}

export type PatientInfo = {
  patientId: string;
};

export interface OrthoCanvasInformationObject extends CanvasInformationObject {
  patientInfo?: PatientInfo;
  croppedImages?: ListImages;
  claimLevelOjrrFindings?: OrthoFindings;
  claimPrediction: OrthoClaimPrediction;
}

export type ListImages = {
  [key: string]: OrthoImageDetails;
};

export type OrthoImageDetails = {
  sourceImage: string;
  isNewTag: boolean;
  isHistoricImage: boolean;
  imageReceiptDate: string;
  imageType?: string;
  content?: string;
  imageThumbnailUri: string;
};

export type OrthoFinding = {
  description: string;
  value: string | undefined;
};

export type OrthoImageFindingDetail = {
  key: string;
  description: string;
  value: string | undefined;
  color: string;
};

export type OrthoImagePredictionOptions = {
  intersection_point?: {
    x: number;
    y: number;
  };
};

export type OrthoImagePrediction = {
  imageUri: string;
  show: boolean;
  title: string;
  type: ORTHO_IMAGE_TYPE;
  polygons: FindingI;
  sidePanel: OrthoImageFindingDetail[];
  options?: OrthoImagePredictionOptions;
};

export type OrthoImagePredictions = {
  [key: string]: OrthoImagePrediction;
};

export type OrthoScore = {
  findings: OrthoFinding[];
  totalScore?: string;
  threshold?: string;
};

export type OrthoFindings = {
  autoQualifier?: OrthoFinding[];
  hldScoring?: OrthoScore;
  salzmannScoring?: OrthoScore;
};

export type OrthoImage = {
  type: string;
  sourceImage: string | undefined;
  thumbnail: string | undefined;
};

export type OrthoImageObj = {
  [key: string]: OrthoImage;
};

export interface OrthoGeneralObject {
  calibration_box?: number[];
  keypoints?: GenericObject;
  measurements?: GenericObject;
  pixel_calibration?: GenericObject;
}

export interface OrthoImagePredictionI extends ImagePredictionI {
  jsonContent?: GenericObject;
  thumbnail?: string;
  ortho_general?: OrthoGeneralObject;
}

export interface SpacingOpen {
  bbox: Point[];
  line_geometry: Point[];
  space_mm: number;
  space_pixels: number;
  tn_pair: string[];
}

export interface OrthoImagePredictionObject {
  [key: string]: OrthoImagePredictionI;
}

export interface ImagePath {
  img_id: string;
}

export interface ImagePaths {
  ceph: ImagePath;
  intra_frontal_occlusion: ImagePath;
  intra_frontal_rest: ImagePath;
  intra_lateral_left: ImagePath;
  intra_lateral_right: ImagePath;
  intra_occlusal_lower: ImagePath;
  intra_occlusal_upper: ImagePath;
  pan: ImagePath;
}

export const RESET_CONTEXT = "RESET_CONTEXT";
export const GET_CANVAS_INFORMATION_IN_PROGRESS: string =
  "GET_CANVAS_INFORMATION_IN_PROGRESS";
export const GET_CANVAS_INFORMATION_SUCCESSFUL: string =
  "GET_CANVAS_INFORMATION_SUCCESSFUL";
export const GET_CANVAS_INFORMATION_FAILED: string =
  "GET_CANVAS_INFORMATION_FAILED";
export const GET_CLAIM_PREDICTION_IN_PROGRESS: string =
  "GET_CLAIM_PREDICTION_IN_PROGRESS";
export const GET_CLAIM_PREDICTION_SUCCESSFUL: string =
  "GET_CLAIM_PREDICTION_SUCCESSFUL";
export const GET_CLAIM_PREDICTION_FAILED: string =
  "GET_CLAIM_PREDICTION_FAILED";

export enum ORTHO_IMAGE_TYPE {
  INTRA_OCCLUSAL_UPPER = "intra_occlusal_upper",
  INTRA_FRONTAL_REST = "intra_frontal_rest",
  INTRA_OCCLUSAL_LOWER = "intra_occlusal_lower",
  INTRA_LATERAL_LEFT = "intra_lateral_left",
  INTRA_FRONTAL_OCCLUSION = "intra_frontal_occlusion",
  INTRA_LATERAL_RIGHT = "intra_lateral_right",
  PAN = "pan",
  CEPH = "ceph",
}

export const OrthoImageTypeMapping = {
  [ORTHO_IMAGE_TYPE.INTRA_OCCLUSAL_UPPER]: "intra-occlusal-upper",
  [ORTHO_IMAGE_TYPE.INTRA_FRONTAL_REST]: "intra-frontal-rest",
  [ORTHO_IMAGE_TYPE.INTRA_OCCLUSAL_LOWER]: "intra-occlusal-lower",
  [ORTHO_IMAGE_TYPE.INTRA_LATERAL_LEFT]: "intra-lateral-left",
  [ORTHO_IMAGE_TYPE.INTRA_FRONTAL_OCCLUSION]: "intra-frontal-occlusion",
  [ORTHO_IMAGE_TYPE.INTRA_LATERAL_RIGHT]: "intra-lateral-right",
  [ORTHO_IMAGE_TYPE.PAN]: "pan",
  [ORTHO_IMAGE_TYPE.CEPH]: "ceph",
};

export function orthoImageTypeToHyphenated(type: ORTHO_IMAGE_TYPE): string {
  return OrthoImageTypeMapping[type];
}

export const initialOrthoContext: OrthoContextI = {
  state: {
    claimLoading: false,
    claimLoaded: false,
    claimInformation: {
      orthoFindings: {
        autoQualifier: [],
        hldScoring: {
          findings: [],
          totalScore: undefined,
          threshold: undefined,
        },
        salzmannScoring: {
          findings: [],
          totalScore: undefined,
          threshold: undefined,
        },
      },
      supportedServiceLines: [],
      unSupportedServiceLines: [],
    },
    imagePredictionLoading: false,
    imagePredictionLoaded: false,
    orthoImagePredictions: {},
  },
};

export type FrontalCrossbite = {
  frontal_teeth?: string[];
  gingival_margin_recession?: string[];
};

export type FrontalMandibularProtrusion = {
  ceph?: number;
  ceph_round?: number;
  frontal?: null;
  frontal_round?: null;
  frontal_teeth?: string[];
};

export type FrontalOpenbite = {
  frontal?: number;
  frontal_round?: number;
  frontal_upper_jaw?: string[];
};

export type FrontalOverbite = {
  ceph?: number;
  ceph_round?: number;
  deep_impinging_overbite?: boolean;
  frontal?: null;
  frontal_round?: null;
  frontal_upper_jaw?: string[];
  severe_soft_tissue_damage_boolean_single?: null;
};

export type FrontalOverjet = {
  ceph?: number;
  ceph_round?: number;
  frontal?: null;
  frontal_round?: null;
  frontal_teeth?: string[];
  incompetent_lips?: null;
};

export type Frontal = {
  crossbite?: FrontalCrossbite;
  mandibular_protrusion?: FrontalMandibularProtrusion;
  openbite?: FrontalOpenbite;
  overbite?: FrontalOverbite;
  overjet?: FrontalOverjet;
};

export type ClassDiscrepancy = {
  right_teeth?: string[];
  left_teeth?: string[];
  mb_cusp_to_buccal_groove?: number;
  mb_cusp_to_buccal_groove_round?: number;
};

export type PosteriorCrossbite = {
  complete_crossbite?: null;
  left_teeth?: string[];
  right_teeth?: string[];
};

export type PosteriorOpenbite = {
  left?: number;
  left_round?: number;
  left_teeth?: string[];
  right?: number;
  right_round?: number;
  right_teeth?: string[];
};

export interface Posterior {
  // [todo]: we should show tooth numbers instead of Class 3
  class_II_discrepancy?: ClassDiscrepancy;
  class_III_discrepancy?: ClassDiscrepancy;
  posterior_crossbite?: PosteriorCrossbite;
  posterior_openbite?: PosteriorOpenbite;
}

export type InterArchDeviation = {
  frontal?: Frontal;
  posterior_left?: Posterior;
  posterior_right?: Posterior;
};

export type ArchDeviation = {
  canine2canine_insufficiency?: number | null;
  canine2canine_insufficiency_round?: number | null;
  crowded?: string[];
  ectopic?: string[];
  impacted?: string[];
  labio_lingual_spread?: number;
  labio_lingual_spread_round?: number;
  missing?: string[];
  molar2molar_insufficiency?: number | null;
  molar2molar_insufficiency_round?: number | null;
  rotated?: string[];
  spacing_closed?: string[];
  spacing_open_pairs?: string[][];
};

export type IntraArchDeviation = {
  mandible?: ArchDeviation;
  maxilla?: ArchDeviation;
};

export type OrthoClaimPrediction = {
  claim_ml_aggregator_version: string;
  image_paths: ImagePaths;
  inter_arch_deviation: InterArchDeviation;
  intra_arch_deviation: IntraArchDeviation;
  version: string;
};

export type CephFinding = {
  overjet?: number;
  overbite?: number;
  reverse_overjet?: number;
};

export type PanoFinding = {
  ectopic?: string[];
  impacted?: string[];
};

export type FrontalFinding = {
  ectopic?: string[];
  openbite?: FrontalOpenbite;
  overbite?: FrontalOverbite;
  overjet?: FrontalOverjet;
  crossbite?: string[];
};

export type OcclusionFinding = {
  ectopic?: string[];
  // Anterior crowding - canine2canine_insufficiency
  canine2canine_insufficiency?: null | number;
  // Full arch crowding - molar2molar_insufficiency
  molar2molar_insufficiency?: null | number;
  labio_lingual_spread?: number;
  crowded?: string[];
  rotated?: string[];
  spacing_open_pairs?: string[][];
  spacing_closed?: string[];
};

export type PosteriorFinding = {
  ectopic?: string[];
  crossbite?: string[];
  class_II_discrepancy?: ClassDiscrepancy;
  class_III_discrepancy?: ClassDiscrepancy;
  openbite?: string[];
};

export type Tooth = {
  cej_cej_px_length: null;
  corona: any[];
  eruption: any[];
  geometry: [
    {
      x: number;
      y: number;
    }[],
  ];
  impaction: any[];
  incisal_edge_involvement_ratio: null;
  label: string;
  points: {
    embrasure_mesial: {
      imputed: boolean[];
      is_visible: boolean[];
      surface: string[];
      value: {
        x: number;
        y: number;
      }[];
    };
    mb_cusp: {
      imputed: boolean[];
      is_visible: boolean[];
      surface: (string | null)[];
      value: {
        x: number;
        y: number;
      }[];
    };
    proximal_distal: {
      imputed: boolean[];
      is_visible: boolean[];
      surface: string[];
      value: {
        x: number;
        y: number;
      }[];
    };
    proximal_mesial: {
      imputed: boolean[];
      is_visible: boolean[];
      surface: string[];
      value: {
        x: number;
        y: number;
      }[];
    };
    vertical_gingival: {
      imputed: boolean[];
      is_visible: boolean[];
      surface: string[];
      value: {
        x: number;
        y: number;
      }[];
    };
    vertical_incisal: {
      imputed: boolean[];
      is_visible: boolean[];
      surface: string[];
      value: {
        x: number;
        y: number;
      }[];
    };
  };
  ratios: {
    corona_tooth_overlap_ratio: number;
    gap_to_width_ratio: number;
    tooth_visibility_ratio: number;
  };
  root: any[];
  root_tip_non_restorable: null;
  surfaces: any;
  tooth_number_uns: string;
};
