import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import { USER_PROFILE_API, USER_SIGNOUT } from "src/constants/api-urls";
import { CacheKeys } from "src/constants/cache-keys";
import { ERROR_MSGS } from "src/constants/errors";
import { GenericObject, IClaims } from "src/defs";
import { ReportConfig } from "src/components/reporting/types";
import { EventConstants } from "src/components/new-design/utility/constants";
import { QueryType } from "src/defs";

import cacheService, { CacheService } from "./cache.service";
import eventsService, { EventsService } from "./events.service";
import { CanvasConfig } from "./user.service.interface";
import {
  UserProfile,
  ClientConfig,
  SAMLattributes,
  PredictionConfigI,
} from "./types";
import { HttpError } from "src/shared/HttpError";

export class UserService {
  _userProfile: UserProfile | undefined = undefined;

  constructor(
    private _cacheService: CacheService,
    private _eventService: EventsService
  ) {
    const userProfile: UserProfile = _cacheService.getItemsSync(
      CacheKeys.UserProfile
    );
    if (userProfile) this._userProfile = userProfile;
    // * To avoid race conditions of sequential service loading
    import("./claims-table.service").then((module: any) => {
      module.default.setVisibleColumnsFromCache();
    });
    import("./queue-table.service").then((module: any) => {
      module.default.setVisibleColumnsFromCache();
    });
    import("./fwa.service").then((module: any) => {
      module.default.setVisibleColumnsFromCache();
    });
  }
  getMyProfile(
    token: string,
    clientId: string,
    gcpTenantId?: string,
    accountId?: string
  ): Promise<UserProfile> {
    let headers: any = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "x-oj-client-id": clientId,
    };
    if (accountId) {
      headers = {
        ...headers,
        "x-oj-account-pick-client-id": accountId,
      };
    }
    if (gcpTenantId) {
      headers = {
        ...headers,
        "X-OJ-TENANT-ID": gcpTenantId,
      };
    }
    return fetch(USER_PROFILE_API, {
      headers: headers,
    })
      .then((res: any) => {
        if (!res.ok) {
          throw new HttpError(ERROR_MSGS.UNABLE_LOAD_USER_DETAILS, res.status);
        }
        return res.json();
      })
      .catch((err: any) => {
        throw new HttpError(ERROR_MSGS.UNABLE_LOAD_USER_DETAILS, err.status);
      });
  }
  setUserProfile(userProfile: any, persistent = true) {
    const updatedUserProfile = this.createUserProfileForLS(userProfile);
    if (persistent) {
      this._cacheService.setItemSync(CacheKeys.UserProfile, updatedUserProfile);
    }

    this._userProfile = updatedUserProfile;
    this._eventService.emit(
      EventConstants.TRACK_SET_PROFILE,
      updatedUserProfile
    );
  }
  setSAMLAttributes(profile: GenericObject) {
    if (profile) {
      this._cacheService.setItemSync(CacheKeys.SAMLattributes, profile);
    }
  }
  setClaimTableConfig(claimConfig: any) {
    this._cacheService.setItemSync(CacheKeys.ClaimsTableConfig, claimConfig);
  }
  setClaimTablConfigByVersion(claimConfig: any, version = 1) {
    if (version === 1) {
      this.setClaimTableConfig(claimConfig);
    } else {
      this.setClaimLevelClaimTableConfig(claimConfig);
    }
  }
  setClaimLevelClaimTableConfig(claimConfig: any) {
    this._cacheService.setItemSync(
      CacheKeys.ClaimLevelClaimsTableConfig,
      claimConfig
    );
  }
  setClaimLevelQueueTableConfig(claimConfig: any) {
    this._cacheService.setItemSync(
      CacheKeys.ClaimLevelQueueTableConfig,
      claimConfig
    );
  }
  setQueueTableConfig(claimConfig: any) {
    this._cacheService.setItemSync(CacheKeys.QueueTableConfig, claimConfig);
  }
  setQueueTableConfigByVersion(claimConfig: any, version = 1) {
    if (version === 1) {
      this.setQueueTableConfig(claimConfig);
    } else {
      this.setClaimLevelQueueTableConfig(claimConfig);
    }
  }

  setQueueFromLastSavedSession(queueConfig: any) {
    this._cacheService.setItemSync(
      CacheKeys.LastStoredQueueConfig,
      queueConfig
    );
  }

  getQueueFromLastSavedSession() {
    return this._cacheService.getItemsSync(CacheKeys.LastStoredQueueConfig);
  }
  setSummaryFilterConfig(summaryFilterConfig: any) {
    this._cacheService.setItemSync(
      CacheKeys.SummaryFilterConfig,
      summaryFilterConfig
    );
  }
  setFWADuplicatesConfig(duplicatesConfig: any) {
    this._cacheService.setItemSync(
      CacheKeys.FWADuplicatesConfig,
      duplicatesConfig
    );
  }

  setFWASummaryConfig(summaryConfig: any) {
    this._cacheService.setItemSync(CacheKeys.FWASummaryConfig, summaryConfig);
  }
  setPredictionConfig(predictionConfig: any) {
    if (this._userProfile?.clientConfig.predictionConfig) {
      this._userProfile.clientConfig.predictionConfig = predictionConfig;
    }
    this._cacheService.setItemSync(CacheKeys.UserProfile, this._userProfile);
  }
  setReportsConfig(config: Array<ReportConfig>) {
    this._cacheService.setItemSync(CacheKeys.reportsConfig, config);
  }
  getClaimsTableConfigByVersion(version = 1) {
    if (version === 1) return this.getClaimTableConfig();
    return this.getClaimLevelClaimTableConfig();
  }
  getQueueTableConfigByVersion(version = 1) {
    if (version === 1) return this.getQueueTableConfig();
    return this.getClaimLevelQueueTableConfig();
  }
  getClaimTableConfig() {
    return this._cacheService.getItemsSync(CacheKeys.ClaimsTableConfig);
  }
  getClaimLevelClaimTableConfig() {
    return this._cacheService.getItemsSync(
      CacheKeys.ClaimLevelClaimsTableConfig
    );
  }
  getSAMLattributes(): SAMLattributes {
    return this._cacheService.getItemsSync(CacheKeys.SAMLattributes);
  }
  getQueueTableConfig() {
    return this._cacheService.getItemsSync(CacheKeys.QueueTableConfig);
  }
  getClaimLevelQueueTableConfig() {
    return this._cacheService.getItemsSync(
      CacheKeys.ClaimLevelQueueTableConfig
    );
  }
  getSummaryFilterConfig() {
    return this._cacheService.getItemsSync(CacheKeys.SummaryFilterConfig);
  }
  getFWADuplicatesConfig() {
    return this._cacheService.getItemsSync(CacheKeys.FWADuplicatesConfig);
  }
  getFWASummaryConfig() {
    return this._cacheService.getItemsSync(CacheKeys.FWASummaryConfig);
  }
  getActiveClientId(): string {
    const userProfile = this.getUserProfile();
    if (userProfile) {
      const { authorities } = userProfile;
      const [authority] = authorities || [];
      if (authority) {
        return authority.authority;
      }
    }
    return "";
  }
  getAuthClientId(): string {
    return (
      `${this._cacheService.getItemsSync(CacheKeys.authClientConfig)}` || ""
    );
  }
  getUserProfile() {
    return this._userProfile || ({} as UserProfile);
  }

  getClaimLevelQueueConfig() {
    try {
      return this.getUserProfile()?.clientConfig.claimLevelQueueConfig;
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }

  getCrowdingDisplayConfig() {
    return this.getUserProfile()?.clientConfig?.crowdingDisplayConfig || [];
  }

  getHeadersConfig() {
    try {
      return this.getUserProfile()?.clientConfig.headersConfig;
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }

  getColorDecisionField() {
    try {
      return this.getUserProfile()?.clientConfig?.colorDecisionField;
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }

  getOverjetReviewCodeDisplayAlteration() {
    try {
      return (
        this.getUserProfile()?.clientConfig
          ?.overjetReviewCodeDisplayAlteration || false
      );
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }

  getClaimLevelHeadersConfig() {
    try {
      return this.getUserProfile()?.clientConfig.claimLevelHeadersConfig;
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }
  getHeadersConfigByVersion(version = 1) {
    try {
      if (version === 1) {
        return this.getHeadersConfig();
      }
      return this.getClaimLevelHeadersConfig();
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }

  getClientHeaders() {
    try {
      const headersConfig: any = [];
      const headers = this.getUserProfile()?.clientConfig.headersConfig;
      if (headers) {
        headers.forEach((item: any) => {
          if (item.checked === true) {
            headersConfig.push(item.colName);
          }
        });
        return headersConfig;
      }
      return this.getUserProfile()?.clientConfig.clientHeaders;
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }
  getReportsConfig(): Array<ReportConfig> | null {
    return this._cacheService.getItemsSync(CacheKeys.reportsConfig);
  }
  updateClientHeaders(updatedHeader: IClaims.IVisibleColsDictionary) {
    let headersConfig: any = Object.values(updatedHeader).map((v) => v);
    const userProfile = this._userProfile;
    if (userProfile?.clientConfig?.headersConfig) {
      userProfile.clientConfig.headersConfig = headersConfig;
    }
    this._cacheService.setItemSync(CacheKeys.UserProfile, userProfile);
  }
  updateFWAClientHeaders(updatedHeader: any) {
    let headersConfig = Object.values(updatedHeader).map((v) => v);
    const userProfile = this._userProfile;
    if (userProfile) {
      userProfile.clientConfig.fraudWasteAbuse.clientHeadersConfig =
        headersConfig;
    }
    this._cacheService.setItemSync(CacheKeys.UserProfile, userProfile);
  }
  getPredictionConfig(): PredictionConfigI {
    try {
      if (this.getUserProfile()) {
        return this.getUserProfile()?.clientConfig?.predictionConfig || {};
      } else {
        return {};
      }
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }
  getClientConfig(): ClientConfig {
    try {
      return this.getUserProfile().clientConfig;
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }
  getCanvasConfig(): CanvasConfig {
    try {
      return this.getUserProfile()?.clientConfig?.canvasConfig || {};
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }

  getAnnotationDisplayRules() {
    try {
      return this.getUserProfile()?.clientConfig?.annotationDisplayRules || [];
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }

  getClaimLevelCanvasConfig(): CanvasConfig {
    try {
      return this.getUserProfile()?.clientConfig?.claimLevelCanvasConfig || {};
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }

  getTabConfig() {
    try {
      return this.getUserProfile().clientConfig.tabConfig;
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }

  getGraphqlQueries(): QueryType | null {
    try {
      return this.getUserProfile().clientConfig?.graphqlQueries || null;
    } catch (e) {
      throw new Error(ERROR_MSGS.CLIENT_CONFIG_MISSING);
    }
  }

  signOut(token: string, clientId: string, gcpTenantId?: string) {
    const authClientId = this.getAuthClientId();
    let headers: any = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "x-oj-client-id": clientId,
    };
    if (authClientId.split(",").length > 1) {
      headers = {
        ...headers,
        "x-oj-account-pick-client-id": clientId,
        "x-oj-client-id": authClientId,
      };
    }
    if (gcpTenantId) {
      headers = {
        ...headers,
        "X-OJ-TENANT-ID": gcpTenantId,
      };
    }
    this._userProfile = undefined;
    this._eventService.emit(EventConstants.TRACK_UNSET_PROFILE);
    return fetch(USER_SIGNOUT, {
      method: "GET",
      headers: headers,
    });
  }

  /**
   * Checks whether the route has the required permissions
   * @param route The route name
   * @returns Boolean, String or undefined
   */
  hasRouteAccess(route: string) {
    try {
      if (!route || !this._userProfile) return false;
      const { permissionObject } = this._userProfile;
      return Object.keys(permissionObject).find((permission: string) => {
        const permissionKey = permission.split("-")[0];
        return route === permissionKey;
      });
    } catch (e) {
      throw new Error(String(e));
    }
  }

  getComponentAccess(componentName: string): boolean {
    if (
      !componentName ||
      !this._userProfile ||
      isEmpty(this._userProfile.permissionObject)
    ) {
      return false;
    }
    const { permissionObject } = this._userProfile;
    const module = `${componentName.split("-")[0]}-all`;
    if (has(permissionObject, module)) return true;
    else if (has(permissionObject, componentName)) return true;
    return false;
  }

  hasOneOfManyPermission(oneOfManyPermission: Array<any>): boolean {
    try {
      if (!oneOfManyPermission || !this._userProfile) return false;
      const { permissionObject } = this._userProfile;
      return oneOfManyPermission.some((permission: any) => {
        if (typeof permission === "string") {
          return has(permissionObject, permission);
        } else if (
          (isObject(permission) as any) &&
          permission.page &&
          permission.permission
        ) {
          const computedPermission = `${permission.page}-${permission.permission}`;
          return has(permissionObject, computedPermission);
        } else return false;
      });
    } catch (error) {
      console.error("Error in hasOneOfManyPermission", error);
      return false;
    }
  }

  createUserProfileForLS = (userProfile: any = {}) => {
    let updatedUserProfile = { ...userProfile };
    if (!isEmpty(updatedUserProfile)) {
      updatedUserProfile = {
        ...updatedUserProfile,
        permissions: undefined,
        permissionObject: this.normalizePermissions(userProfile.permissions),
      };
      delete updatedUserProfile.permissions;
    }
    return updatedUserProfile;
  };
  normalizePermissions(permissions = []) {
    try {
      let normalizedPermissions: any = {};
      if (permissions && permissions.length > 0) {
        permissions.forEach((permission: any) => {
          if (permission.type === "PAGE") {
            const key = `${permission.module}-${permission.key}`;
            normalizedPermissions[key] = {
              description: permission.description,
            };
          }
        });
      }
      return normalizedPermissions;
    } catch (err) {
      console.log("err : ", err);
      throw new Error(
        "Something unexpected happened while normalizing permissions."
      );
    }
  }
}

const userService = new UserService(cacheService, eventsService);
export default userService;
