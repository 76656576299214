export const reasons = [
  "Crown Detected",
  "Insufficient information",
  "No Tooth # Detected",
  "Root Canal Detected",
];

export const pages = {
  DASHBOARD: "dashboard",
  CANVAS: "canvas",
  CONFIG: "config",
  SUMMARY: "claim_summary",
  FWA_HOME: "fwa_home",
  FWA_VIEWER: "fwa_viewer",
  GUARDIAN_REPORTS: "guardian_reports",
  UPLOAD_CLAIMS: "upload_claims",
  QUEUE: "queue",
  REPORTS: "reports",
  FMX: "fmx",
};

export const dashboardPermissions = {
  DASHBOARD: {
    "DASHBOARD-ALL": "dashboard-all",
    "DASHBOARD-CLAIM_ANALYTICS_CARDS-VIEW":
      "dashboard-claim_analytics_cards-view",
    "DASHBOARD-SEARCH-VIEW": "dashboard-search-view",
    "DASHBOARD-PROCEDURE_TYPE-VIEW": "dashboard-procedure_type-view",
    "DASHBOARD-CALENDAR-VIEW": "dashboard-calendar-view",
    "DASHBOARD-CLAIM_TABLE-VIEW": "dashboard-claim_table-view",
    "DASHBOARD-CLAIM_TABLE_COLUMN_VIEW": "dashboard-claim-table-columns-view",
    "DASHBOARD-SYSTEM-FILTER-VIEW": "dashboard-systemfilter-view",
  },
};

export const queuePermissions = {
  QUEUE: {
    "QUEUE-ALL": "queue-all",
    "QUEUE-CLAIM_ANALYTICS_CARDS-VIEW": "queue-claim_analytics_cards-view",
    "QUEUE-SEARCH-VIEW": "queue-search-view",
    "QUEUE-PROCEDURE_TYPE-VIEW": "queue-procedure-type-view",
    "QUEUE-CLAIM_TABLE-VIEW": "queue-claim_table-view",
    "QUEUE-CLAIM_TABLE_COLUMN_VIEW": "queue-claim-table-columns-view",
    "QUEUE-CLAIM_TYPE_VIEW": "queue-claim-type-view",
    "QUEUE-STATE_TYPE_VIEW": "queue-state-type-view",
    "QUEUE-SPECIALTY_FILTER_VIEW": "queue-specialty-filter-view",
  },
};

export const configPermissions = {
  CONFIG: {
    "CONFIG-ALL": "config-all",
    "CONFIG-VIEW": "config-view",
    "CONFIG-UPDATE": "config-update",
  },
};

export const summaryPermissions = {
  SUMMARY: {
    "CLAIM_SUMMARY-ALL": "claim_summary-all",
    "CLAIM_SUMMARY-CLAIM_ANALYTICS_CARDS-VIEW":
      "claim_summary-claim_analytics_cards-view",
    "CLAIM_SUMMARY-CLAIM_SUMMARY_TABLE-VIEW":
      "claim_summary-claim_summary_table-view",
    "CLAIM_SUMMARY-DOWNLOAD_BUTTON-VIEW": "claim_summary-download_button-view",
    "CLAIM_SUMMARY-PROCEDURE_TYPE-VIEW": "claim_summary-procedure_type-view",
    "CLAIM_SUMMARY-CALENDAR-VIEW": "claim_summary-calendar-view",
  },
};
export const canvasPermissions = {
  CANVAS: {
    "CANVAS-ALL": "canvas-all",
    "CANVAS-CANVAS_AREA-VIEW": "canvas-canvas_area-view",
    "CANVAS-FINDINGS_SIDEBAR-VIEW": "canvas-findings_sidebar-view",
    "CANVAS-ANNOTATIONS-VIEW": "canvas-annotations-view",
    "CANVAS-ADJUDICATION-VIEW": "canvas-adjudication-view",
    "CANVAS-ADJUDICATION-UPDATE": "canvas-adjudication-update",
  },
};

export const fwaPermissions = {
  FWA: {
    "FWA_HOME-ALL": "fwa_home-all",
    "FWA_HOME-DUPLICATE_DATA_CARDS-VIEW": "fwa_home-duplicate_data_cards-view",
    "FWA_HOME-CALENDAR-VIEW": "fwa_home-calendar-view",
    "FWA_HOME-DUPLICATE_TABLE-VIEW": "fwa_home-duplicate_table-view",
    "FWA_VIEWER-ALL": "fwa_viewer-all",
    "FWA_VIEWER-CONFIRM_BUTTON-VIEW": "fwa_viewer-confirm_button-view",
    "FWA_VIEWER-CANCEL_BUTTON-VIEW": "fwa_viewer-cancel_button-view",
  },
};
export const guardianPermissions = {
  GUARDIAN: {
    "GUARDIAN_REPORTS-ALL": "guardian_reports-all",
    "GUARDIAN_REPORTS-PROCEDURE_TYPE-VIEW":
      "guardian_reports-procedure_type-view",
    "GUARDIAN_REPORTS-DATE_PICKER-VIEW": "guardian_reports-date_picker-view",
    "GUARDIAN_REPORTS-DONWLOAD_BUTTON-VIEW":
      "guardian_reports-download_button-view",
  },
};

export const reportsPermissions = {
  REPORTS_DATE_PICKER_VIEW: {
    permission: "date_picker_view",
    page: "reports",
  },
};

export const roles = {
  ADMIN: "admin",
  CLINICIAN: "clinician",
};

export const AMERITAS_ATTESTATION_REQUIRED_ROLES = [
  "AMERITAS_DENTAL_CONSULTANT",
  "ADE_DENTAL_CONSULTANT",
];

export enum CLAIM_ACTION_STATUS {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export const AMERITAS_ROLES = {
  DENTAL_CONSULTANT: "AMERITAS_DENTAL_CONSULTANT",
};
