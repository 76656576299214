import React from "react";

import { ApolloProvider, ApolloClient } from "@apollo/client";
import { Provider } from "react-redux";

import AppRoutes from "src/routes/app-routes";
import { preBoot } from "src/configs/preboot";
import { store } from "src/redux/store";
export const dependenices = preBoot();

export const client: ApolloClient<any> = dependenices.apolloClient;
const App = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </ApolloProvider>
  );
};

export default App;
declare global {
  interface Window {
    newrelic: any;
  }
}
