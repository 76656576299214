import { initializeApollo, firebaseConfig } from "src/configs";
import { GRAPHQL_URL } from "src/constants/api-urls";
import { authService } from "src/services";

import "antd/dist/antd.css";
import "src/App.scss";

interface Dependencies {
  apolloClient: any;
}
export function preBoot(): Dependencies {
  let apolloClient;
  try {
    const dependencies: Dependencies = { apolloClient: null };
    authService.initialize(firebaseConfig);
    apolloClient = initializeApollo(GRAPHQL_URL);

    dependencies.apolloClient = apolloClient;
    return dependencies;
  } catch (e) {
    let errMesssage;
    if (e instanceof Error) {
      errMesssage = `[Pre App Initization] - ${e.message}`;
    }
    console.error(errMesssage);
    throw new Error(errMesssage);
  }
}
