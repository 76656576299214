import * as CryptoJS from "src/shared/libs/crypto";

export const SKey = `${process.env.REACT_APP_CRYPTO_SECRET_KEY}`;

export class CacheService {
  serialize(payload: any) {
    const premitives = ["string", "boolean", "number", "undefined"];
    if (premitives.find((p) => p === typeof payload))
      return CryptoJS.AES.encrypt(payload, SKey).toString();
    else if (typeof payload === "object")
      return CryptoJS.AES.encrypt(JSON.stringify(payload), SKey).toString();
    else throw new Error("Invalid Payload, Unable to serialize");
  }
  deserialize(payload: any) {
    try {
      const parseable = JSON.parse(payload);
      return parseable;
    } catch (e) {
      return payload;
    }
  }

  setItemSync(key: string, payload: any) {
    localStorage.setItem(key, this.serialize(payload));
  }

  getItemsSync(key: string) {
    try {
      let data = localStorage.getItem(key);
      if (data) {
        const bytes = CryptoJS.AES.decrypt(data.toString(), SKey);
        data = bytes.toString(CryptoJS.encUTF8);
      }
      return this.deserialize(data);
    } catch (err) {
      this.clear();
      window.location.href = window.location.origin;
    }
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

const cacheService = new CacheService();
export default cacheService;
