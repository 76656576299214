import { useRef, useEffect } from "react";
import { ProcedureType } from "src/shared/types";
import { USER_PROFILE_API } from "src/constants/api-urls";
import { GenericObject } from "src/defs";
import { CLAIM_TYPES, STATES } from "src/constants";
import { authService, userService, cacheService } from "src/services";

export function usePreviousProp<T>(prop: T | undefined): T | undefined {
  const ref = useRef<T | undefined>(prop);
  useEffect(() => {
    ref.current = prop;
  }, [prop]);
  return ref.current;
}

/**
 * Get list of procedures
 * @param activeProcedures Active procedures
 */
export const getListOfProcedure = (
  activeProcedures: any
): Array<ProcedureType> => {
  return activeProcedures.map((item: any) => {
    return {
      key: item,
      title: item,
    };
  });
};

/**
 * List of updated procedures
 * @param activeProcedures Active procedures
 * @param procedureType Procedure type
 */
export const getListOfUpdatedProcedure = (
  activeProcedures: any,
  procedureType: any
): Array<ProcedureType> => {
  const proceduresArr = Array.isArray(activeProcedures)
    ? activeProcedures
    : [
        {
          key: activeProcedures,
          title: activeProcedures,
        },
      ];
  let allExists = proceduresArr.find(
    (procedure: any) => procedure.key === "ALL"
  );
  if (allExists) {
    return procedureType.activeProcedures.map((item: any) => {
      return {
        key: item,
        title: item,
      };
    });
  } else {
    return proceduresArr.map((item: any) => {
      return {
        key: item,
        title: item,
      };
    });
  }
};

export const getListOfUpdatedClaimType = (claimTyes: Array<any>) => {
  return claimTyes.map((item) => {
    let claimTypeConstant = CLAIM_TYPES.find(
      (claimTypeItem) => claimTypeItem.key === item
    );
    return {
      key: item,
      title: claimTypeConstant?.title,
    };
  });
};

export const getListOfUpdatedStateFilter = (stateFilter: Array<any>) => {
  return stateFilter.map((item) => {
    let stateFilterConstant = STATES.find(
      (stateFilterItem) => stateFilterItem.key === item
    );
    return {
      key: item,
      title: stateFilterConstant?.title,
    };
  });
};

/**
 * Get object from collection group by keys
 * @param collection
 */
export const getObjectGroupByKey = (collection: Array<any>) => {
  return collection.reduce((acc, current) => {
    if (current) {
      acc[current] = current;
    }
    return acc;
  }, {} as GenericObject);
};

/**
 * Sort object by keys alphabetically
 * @param param
 */
export const sortObjectByKeys = (param: GenericObject) => {
  return Object.keys(param)
    .sort()
    .reduce((acc, current) => {
      acc[current] = param[current];
      return acc;
    }, {} as GenericObject);
};

/**
 * Fetch fresh data from my-profile API
 */
export const refreshMyProfile = async () => {
  try {
    const refreshProfile = cacheService.getItemsSync("refreshProfile");
    const accessToken = await authService.getFirebaseToken();
    if (refreshProfile) return;
    const auth = authService.getAuth();
    const clientId = userService.getAuthClientId();
    let headers: any = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "x-oj-client-id": clientId,
    };
    const accountId = cacheService.getItemsSync("accountId");
    if (accountId) {
      headers = {
        ...headers,
        "x-oj-account-pick-client-id": accountId,
      };
    }
    if (auth?.gcpTenantId) {
      headers = {
        ...headers,
        "X-OJ-TENANT-ID": auth?.gcpTenantId,
      };
    }
    const profile = await fetch(USER_PROFILE_API, {
      headers: headers,
    }).then((res: any) => res.json());
    userService.setUserProfile(profile);
    cacheService.setItemSync("refreshProfile", "1");
    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};
