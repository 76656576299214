import loadingImage from "src/assets/images/loading-black.png";
import noImage from "src/assets/images/image-not-found.png";
import noXrayImage from "src/assets/images/no-valid-xray.png";

export const loadingPrediction = [
  {
    imageUri: loadingImage,
    prediction: {
      classifications: {
        image_type: "loading",
      },
    },
    reasons: [],
    sourceImages: loadingImage,
  },
];

export const noPrediction = [
  {
    imageUri: noImage,
    prediction: {},
    reasons: [],
    sourceImages: noImage,
  },
];

export const noXrayFound = [
  {
    imageUri: noXrayImage,
    prediction: {},
    reasons: [],
    sourceImages: noXrayImage,
  },
];

export const sourceImageEmptyPrediction = [
  {
    imageUri: null,
    prediction: {
      classifications: {
        image_type: "source",
      },
    },
    reasons: [],
    sourceImages: null,
    imagePredictionKey: "",
    imageReceiptDate: "",
    isNewTag: "",
  },
];
