import actions from "./actions";

const initState = {
  canvas: null,
  canvasProperties: { strokeWidthFactor: 5, left: 0, zoomLevel: 1 },
  polygons: {},
  polygonsImage: {},
  signedImages: {
    data: null,
    error: null,
    inprogress: false,
  },
};
export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.CANVAS_CURSOR:
      if (state.canvas) {
        state.canvas.defaultCursor =
          state.canvas.defaultCursor === "crosshair" ? "default" : "crosshair";
      }

      return { ...state, canvas: state.canvas };

    case actions.CANVAS_SET_VALUE:
      return { ...state, canvas: action.value };

    case actions.RE_SET_VALUE:
      return { ...state, polygons: {}, polygonsImage: {} };

    case actions.POLYGONS_SET_VALUE:
      return { ...state, polygons: action.value };

    case actions.CANVAS_PROPERTIES_SET_VALUE:
      return {
        ...state,
        canvasProperties: {
          strokeWidth: action.strokeWidth,
          zoomLevel: action.zoomLevel,
        },
      };

    case actions.POLYGONS_REMOVE_VALUE: {
      return { ...state, polygons: {}, polygonsImage: {} };
    }

    case actions.POLYGONS_SET_BACKGROUND_IMAGE:
      return { ...state, polygonsImage: action.value };

    default:
      return state;
  }
}
