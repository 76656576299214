import { GenericObject } from "src/defs";
import { CacheKeys } from "src/constants/cache-keys";
import cacheService, { CacheService } from "./cache.service";
import { QueryType } from "src/defs";
import { userService } from "src/services";
import { isEmpty } from "src/shared/libs/lodash";

/**
 * The Query Service to store and return Queries
 */
export class QueryService {
  private _queries: QueryType = {};
  private _claimLevelQueries: QueryType = {};
  constructor(private _cacheService: InstanceType<typeof CacheService>) {
    const queries = _cacheService.getItemsSync(CacheKeys.queries);
    const claimLevelQueries = _cacheService.getItemsSync(
      CacheKeys.claimLevelQueries
    );
    if (queries) {
      this._queries = queries;
    }
    if (claimLevelQueries) {
      this._claimLevelQueries = claimLevelQueries;
    }
  }

  /**
   * Set the queries to caching service as JSON
   * @param queries {GenericObject} - The queries from my-profile
   */
  setQueries(queries: GenericObject) {
    this._cacheService.setItemSync(CacheKeys.queries, JSON.stringify(queries));
    this._queries = queries;
  }

  /**
   * Set the claim level queries to caching service as JSON
   * @param queries {GenericObject} - The queries from my-profile
   */
  setClaimLevelQueries(queries: GenericObject) {
    this._cacheService.setItemSync(
      CacheKeys.claimLevelQueries,
      JSON.stringify(queries)
    );
    this._claimLevelQueries = queries;
  }

  /**
   * Get the queries filtered by version
   *
   * @param version {number} - The version number
   * @returns {QueryType} The query object
   */
  getQueriesByVersion(version: number = 1): QueryType {
    if (version === 1) return this.getQueries();
    return this.getClaimLevelQueries();
  }

  /**
   * Get the queries from the caching service or my-profile API
   * @returns {QueryType} The query object
   */
  getQueries(): QueryType {
    if (isEmpty(this._queries)) {
      const queries = userService.getGraphqlQueries() || {};
      return queries;
    }
    return this._queries;
  }

  /**
   * Get Claim Level Queries from the caching service or my-profile API
   * @returns {QueryType} The query object
   */
  getClaimLevelQueries(): QueryType {
    if (isEmpty(this._claimLevelQueries)) {
      const claimLevelQueries = userService.getGraphqlQueries() || {};
      return claimLevelQueries;
    }
    return this._claimLevelQueries;
  }
}

const queryService = new QueryService(cacheService);
export default queryService;
