import { HttpError } from "src/shared/HttpError";

const interceptFetch = (): void => {
  const originalFetch: typeof window.fetch = window.fetch;
  window.fetch = (
    url: RequestInfo | URL,
    options?: RequestInit | undefined
  ): Promise<Response> => {
    return originalFetch(url, options)
      .then((response: Response) => {
        // Post-request
        if (!response.ok) {
          const httpError = new HttpError(response.url, response.status);
          window?.newrelic?.noticeError(httpError);
        }
        return response;
      })
      .catch((error) => {
        // Post-request
        throw error;
      });
  };
};
export default interceptFetch;
