import { ImageMap, QuadrantToFmxQuadType } from "./types";

const grid: Array<number> = Array.from({ length: 21 }).map((_, i) => i);

const quadrantToFmxQuadrant: QuadrantToFmxQuadType = {
  UR: ["a1", "d1", "a2", "d2", "b1", "b2"],
  UL: ["b2", "b3", "c1", "e1", "e2", "c2"],
  LL: ["e2", "h2", "e1", "h1", "g2", "g3"],
  LR: ["g1", "g2", "d2", "f2", "d1", "f1"],
};

const quadrants: Array<string> = [
  "a1",
  "a2",
  "b1",
  "b2",
  "b3",
  "c1",
  "c2",
  "d1",
  "d2",
  "e1",
  "e2",
  "f1",
  "f2",
  "g1",
  "g2",
  "g3",
  "h1",
  "h2",
];
const imageMapping: Array<ImageMap> = grid.map((i: number) => {
  const numKey: number = i + 1;
  let className: string = "";
  if (numKey >= 15 && numKey <= 21) {
    className = "align-self-end";
  }
  if (numKey >= 8 && numKey <= 14) {
    className = "align-self-center";
  }
  if (numKey >= 1 && numKey <= 7) {
    className = "align-self-start";
  }
  let mapping: string | null = null;
  if (numKey > 0 && numKey <= 9) {
    const foundMapping = quadrants
      .slice(0, 9)
      .find((quadrant: string, o: number): boolean => o + 1 === numKey);
    if (foundMapping) mapping = foundMapping;
  }
  if (numKey > 12 && numKey <= 21) {
    const foundMapping = quadrants
      .slice(9, 20)
      .find((quadrant: string, o: number): boolean => o + 13 === numKey);
    if (foundMapping) mapping = foundMapping;
  }
  return {
    mapping,
    className,
    key: numKey,
    skip: [10, 11, 12].includes(numKey),
    vertical: [3, 4, 5, 17, 18, 19].includes(numKey),
  };
});

export { imageMapping, quadrantToFmxQuadrant };
