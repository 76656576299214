export const CacheKeys = {
  Auth: "auth",
  ClaimsTableConfig: "claimsTableConfig",
  ClaimLevelClaimsTableConfig: "claimLevelClaimsTableConfig",
  QueueTableConfig: "queueTableConfig",
  ClaimLevelQueueTableConfig: "claimLevelQueueTableConfig",
  UserProfile: "userProfile",
  LastUnload: "lastUnload",
  OJSSO: "OJSSO",
  SummaryFilterConfig: "summaryFilterConfig",
  FWADuplicatesConfig: "fwaDuplicatesConfig",
  FWASummaryConfig: "fwaSummaryConfig",
  fmxParams: "fmxParams",
  urlParams: "urlParams",
  logoutBackup: "logoutBackup",
  redirectURL: "redirectURL",
  queries: "queries",
  claimLevelQueries: "claimLevelQueries",
  reportsConfig: "reportsConfig",
  authClientConfig: "authClientConfig",
  SAMLattributes: "SAMLattributes",
  ClaimPageIds: "claimPageIds",
  LastStoredQueueConfig: "lastStoredQueueConfig",
  USE_FIRST_PARTY_DOMAIN_CALLBACK: "useFirstPartyDomainCallback",
};
