import { FIREBASE } from "src/constants/env-constants";

export const firebaseConfig = {
  apiKey: FIREBASE.API_KEY,
  appId: FIREBASE.APP_ID,
  authDomain: FIREBASE.AUTH_DOMAIN,
  databaseURL: FIREBASE.DB_URL,
  messagingSenderId: FIREBASE.MSG_SENDING_ID,
  projectId: FIREBASE.PROJECT_ID,
  storageBucket: FIREBASE.STORAGE_BUCKET,
};

export const FIRST_PARTY_DOMAIN = "FIRST_PARTY_DOMAIN";
