import firebase from "firebase/app";
import "firebase/auth";

import cacheService, { CacheService } from "./cache.service";
import { IAuth } from "src/defs";
import { CacheKeys } from "src/constants/cache-keys";
import { dependenices } from "src/App";

export class AuthService {
  private _auth: IAuth.IUser = undefined;
  private defaultFirebase: firebase.app.App | null;
  private authStateChangedPromise: Promise<void> | null = null;

  constructor(private _cacheService: InstanceType<typeof CacheService>) {
    const auth = _cacheService.getItemsSync(CacheKeys.Auth);
    if (auth) this.setAuth(auth, false);

    this.defaultFirebase = null;
  }

  setAuth(auth: Partial<IAuth.IUser>, persistant = true) {
    const newAuth = auth
      ? ({ ...this.getAuth(), ...auth } as IAuth.IUser)
      : undefined;
    if (persistant) this._cacheService.setItemSync(CacheKeys.Auth, newAuth);
    this._auth = newAuth;
  }

  getAuth() {
    return this._auth;
  }

  addAuthStateChangedListener(): Promise<void> {
    if (this.authStateChangedPromise) {
      return this.authStateChangedPromise;
    }

    this.authStateChangedPromise = new Promise((resolve, reject) => {
      if (!this.defaultFirebase) {
        return reject(new Error("Default Firebase not initialized"));
      }

      const auth = this.defaultFirebase.auth();
      auth.onAuthStateChanged(
        async (user) => {
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    });

    return this.authStateChangedPromise;
  }

  initialize(config: IAuth.IFirebaseConfig) {
    if (!config.apiKey || !config.authDomain || !config.projectId)
      throw new Error("Invalid Firebase Config");

    this.defaultFirebase = firebase.initializeApp(config);
  }

  getFirebaseAppInstance(): firebase.app.App {
    if (!this.defaultFirebase)
      throw new Error("Default Firebase not initialized");
    return this.defaultFirebase;
  }

  async getFirebaseToken() {
    try {
      await this.addAuthStateChangedListener();
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        return await currentUser.getIdToken();
      } else {
        return Promise.reject("No Firebase user");
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      return Promise.reject(error);
    }
  }

  refreshToken() {
    const firebaseApp = authService.getFirebaseAppInstance();
    firebaseApp.auth().onAuthStateChanged(async (auth) => {
      if (!auth) return;
      const tokenResult = await auth.getIdTokenResult(true);
      if (!tokenResult) return;
      const user = {
        accessToken: tokenResult.token,
        expiringAt: tokenResult.expirationTime,
        issuedAt: tokenResult.issuedAtTime,
      };
      this.setAuth(user);
    });
  }

  signIn(credentials: IAuth.ISigninCredentials) {
    const firebaseApp = authService.getFirebaseAppInstance();
    firebaseApp.auth().tenantId = null;
    return firebaseApp
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password);
  }

  signInTenant(credentials: IAuth.ISigninCredentials) {
    const gcpTenantId =
      process.env["REACT_APP_GCP_TENANT_ID_CLIENT_" + credentials.clientId];
    if (!gcpTenantId) {
      console.error("TenantId is missing in environment");
      return;
    }
    const firebaseApp = authService.getFirebaseAppInstance();
    firebaseApp.auth().tenantId = gcpTenantId;
    let provider = new firebase.auth.GoogleAuthProvider();
    return firebaseApp.auth().signInWithPopup(provider);
  }

  signInWithSSO(idToken: string) {
    const firebaseApp = authService.getFirebaseAppInstance();
    return firebaseApp.auth().signInWithCustomToken(idToken);
  }

  async signOut() {
    this.setAuth(undefined);
    const isBackup = this._cacheService.getItemsSync(CacheKeys.logoutBackup);
    if (isBackup) {
      this._cacheService.removeItem(CacheKeys.Auth);
    } else {
      this._cacheService.clear();
    }
    dependenices.apolloClient.clearStore();
    return firebase.app().auth().signOut();
  }

  async extractDetailsAndUpdateUser(res: any): Promise<IAuth.IUser> {
    const tokenResult = await res.user.getIdTokenResult();
    const auth = {
      accessToken: tokenResult.token,
      gcpTenantId: res.user.tenantId,
      expiringAt: tokenResult.expirationTime,
      issuedAt: tokenResult.issuedAtTime,
      refreshToken: res.user.refreshToken,
      uid: tokenResult.claims.user_id,
    };
    this.setAuth(auth);
    return auth;
  }

  async SSOextractDetailsAndUpdateUser(res: any): Promise<IAuth.IUser> {
    const tokenResult = await res.getIdTokenResult();
    const auth = {
      accessToken: tokenResult.token,
      expiringAt: tokenResult.expirationTime,
      issuedAt: tokenResult.issuedAtTime,
      refreshToken: res?.user?.refreshToken || "",
      uid: tokenResult.claims.user_id,
    };
    this.setAuth(auth);
    return auth;
  }

  async sendPasswordResetEmail(email: string): Promise<any> {
    const firebaseApp = authService.getFirebaseAppInstance();
    return firebaseApp.auth().sendPasswordResetEmail(email);
  }

  async saveUserInfo(): Promise<any> {
    const backup: any = {};
    const { pathname, search } = window.location;
    let lastURL = pathname + search;
    if (!lastURL.includes("/auth")) {
      backup[CacheKeys.urlParams] = lastURL;
    }
    Object.values(CacheKeys).forEach((cacheKey) => {
      if (
        ![
          CacheKeys.OJSSO,
          CacheKeys.Auth,
          CacheKeys.LastUnload,
          CacheKeys.logoutBackup,
          CacheKeys.redirectURL,
          CacheKeys.urlParams,
        ].includes(cacheKey)
      ) {
        backup[cacheKey] = this._cacheService.getItemsSync(cacheKey);
      }
    });
    return backup;
  }
}

const authService = new AuthService(cacheService);
export default authService;
