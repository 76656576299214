import get from "lodash/get";
import { History } from "history";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleXmark,
  faCircleCheck,
  faClock,
  faCircleHalfStroke,
  faCircleQuestion,
  faTooth,
  faHourglassHalf,
} from "@fortawesome/pro-regular-svg-icons";
import { cacheService, userService } from "src/services";
import { overjetReviewColorDarkMode } from "src/constants/color-constants";
import { quadrantToFmxQuadrant } from "src/components/new-design/fmx/fmx-content/constant";
import { ServiceLineObject } from "src/context/canvas/types";
import { MatchService } from "src/components/new-design/fmx/fmx-sidebar/types";

export const getIconByResult = (
  reviewResult: string,
  pendCode?: string
): IconProp => {
  let result = reviewResult;
  const colorDecisionField = userService.getColorDecisionField();
  if (colorDecisionField && colorDecisionField === "code" && pendCode) {
    if (["X1", "X5", "X8"].includes(pendCode)) {
      result = pendCode;
    } else {
      result = pendCode.replace(/[0-9]/g, "");
    }
    // Guardian only
    if (result === "D") return faTooth;
    if (result === "I") return faTooth;
  }
  switch (result) {
    case "A":
    case "AR":
      return faCircleCheck;
    case "D":
      return faCircleXmark;
    case "CR":
    case "DR":
    case "DG":
    case "X1":
    case "IP":
      return faTooth;
    case "P":
    case "X5":
    case "X8":
      return faHourglassHalf;
    case "R":
      return faClock;
    case "PA":
      return faCircleHalfStroke;
    case "PD":
      return faCircleHalfStroke;
    case "I":
      return faCircleQuestion;
    default:
      return faTooth;
  }
};

export const getColorByResult = (
  reviewResult: string,
  pendCode?: string
): string => {
  const colorDecisionField = userService.getColorDecisionField();
  let result = reviewResult;
  if (colorDecisionField && colorDecisionField === "code" && pendCode) {
    if (["X1", "X5", "X8"].includes(pendCode)) {
      result = pendCode;
    } else {
      result = pendCode.replace(/[0-9]/g, "");
    }
    // Guardian only
    if (result === "D" || result === "I")
      return overjetReviewColorDarkMode.CLINICAL_REVIEW;
  }
  switch (result) {
    case "A":
    case "AR":
      return overjetReviewColorDarkMode.ACCEPT;
    case "D":
      return overjetReviewColorDarkMode.DENY;
    case "CR":
    case "DR":
    case "DG":
    case "X1":
    case "IP":
      return overjetReviewColorDarkMode.CLINICAL_REVIEW;
    case "P":
    case "X5":
    case "X8":
      return overjetReviewColorDarkMode.PEND;
    case "PA":
      return overjetReviewColorDarkMode.PARTIAL_ACCEPT;
    case "PD":
      return overjetReviewColorDarkMode.PARTIAL_DENY;
    case "R":
      return overjetReviewColorDarkMode.REVIEW;
    case "I":
      return overjetReviewColorDarkMode.RFI;
    default:
      return "#8FD8CB";
  }
};

export const extractAnnotationDisplayRules = (
  allAnnotationRules: any,
  procedureCode: any
) => {
  const annotationRule = allAnnotationRules.find(
    (annotationDisplaRule: any) => {
      let annotationruleExists = false;
      if (
        annotationDisplaRule?.listOfProcedures &&
        annotationDisplaRule.listOfProcedures.includes(procedureCode)
      ) {
        annotationruleExists = true;
      } else if (annotationDisplaRule.rangeOfProcedures) {
        let procedureValue: any = procedureCode?.slice(
          procedureCode?.indexOf("D") + 1
        );

        annotationDisplaRule.rangeOfProcedures?.forEach(
          (procedureRange: any) => {
            if (
              procedureValue >= procedureRange.min &&
              procedureValue <= procedureRange.max
            ) {
              annotationruleExists = true;
            }
            return annotationruleExists;
          }
        );
      }
      return annotationruleExists;
    }
  );

  return annotationRule;
};

export const getMatchedQuadToFmxQuad = (
  serviceLines?: Array<ServiceLineObject>,
  quadrant?: string,
  predictedTeeth?: Array<string>
): Array<MatchService> => {
  if (serviceLines && serviceLines.length > 0) {
    let annotationDisplaRules = userService.getAnnotationDisplayRules() || [];
    return serviceLines.map((activeServiceLine) => {
      const quadrantFromService = get(activeServiceLine, "quadrant");
      if (quadrantFromService) {
        const quadList = quadrantToFmxQuadrant[quadrantFromService];
        const findingRules = extractAnnotationDisplayRules(
          annotationDisplaRules,
          activeServiceLine.procedure
        );
        if (findingRules?.submissionType === "Quadrant") {
          if (quadrant && quadList.includes(quadrant)) {
            return activeServiceLine;
          }
          return false;
        }
        if (findingRules?.submissionType === "Tooth") {
          if (activeServiceLine.toothNumber) {
            if (
              predictedTeeth?.find(
                (item) => Number(item) === Number(activeServiceLine.toothNumber)
              )
            ) {
              return activeServiceLine;
            } else {
              return false;
            }
          }
        }
      }
      return false;
    });
  }
  return [];
};

export const gotoCanvasPage = (
  history: History,
  selectedService?: ServiceLineObject,
  imageSrc = ""
) => {
  const search = new URLSearchParams(history.location.search);
  search.set("fmxRedirect", "true");
  if (selectedService) {
    const fmxState = {
      selectedServiceId: selectedService.id,
    };
    cacheService.setItemSync("fmxState", fmxState);
    cacheService.setItemSync("currentSelectedImage", imageSrc);
  }
  window.location.replace(`/v2/canvas?${search.toString()}`);
};
