import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import GlobalLoader from "src/components/shared/components/global-loader";
import { guardsService, userService } from "src/services";
import { getDefaultRoute } from "src/components/shared/helper";
import WithClientConfigBoundry from "src/shared/error-boundries/user-profile.boundry";

const Auth = lazy(() => import("src/containers/auth"));
const Authentication = lazy(() => import("src/containers/authentication"));
const AppShell = lazy(() => import("src/containers/shell"));
const HomeContainer = lazy(() => import("src/containers/new-design/home"));

function AppRoutes() {
  const route = getDefaultRoute(userService.getUserProfile());
  let defaultRoute = route || "/";

  return (
    <Router>
      <Suspense fallback={<GlobalLoader />}>
        <Switch>
          <Route
            exact
            path="/auth"
            component={(props: any) => <Auth {...props} />}
          />
          {/*Login route for OverJet employees*/}
          <Route
            exact
            path="/auth-overjet-orvwc"
            component={(props: any) => {
              props = {
                ...props,
                loginTenant: true,
              };
              return <Auth {...props} />;
            }}
          />
          <Route
            exact
            path="/authentication"
            component={(props: any) => <Authentication {...props} />}
          />
          <ProtectedRoute path="/v2">
            <Route path="" component={HomeContainer} />
          </ProtectedRoute>
          <ProtectedRoute path="">
            {/*
            // @ts-ignore */}
            <AppShell />
          </ProtectedRoute>
          <Route render={() => <Redirect to={defaultRoute} />} />
        </Switch>
      </Suspense>
    </Router>
  );
}

// @ts-ignore
function ProtectedRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return guardsService.isAuthorized() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth",
              state: {
                from: location,
              },
            }}
          />
        );
      }}
    />
  );
}

export default WithClientConfigBoundry(AppRoutes);
