const mapping = {
  bone_loss: "Bone Loss",
  global: "Max Bone Level",
  tooth: "Tooth",
};

const getLabel = (key) => {
  if (typeof key === "string" && key?.split("_")[0]) {
    return key.split("_")[0];
  }
  return mapping[key] || key;
};

export default getLabel;
