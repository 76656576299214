export const DATE_FORMAT_DEFAULT = "YYYY-MM-DD";
export const DATE_FORMAT_CUSTOM = {
  "MM/DD/YYYY": "MM/DD/YYYY",
};

export const SUB_DOMAIN = {
  GUARDIAN: "glic",
};

export const ONLY_QUADRANT_PROCEDURES = [
  "D4210",
  "D4211",
  "D4240",
  "D4241",
  "D4260",
  "D4261",
  "D6055",
  "D4341",
  "D4342",
  "D7260",
  "D7261",
  "D7310",
  "D7311",
  "D7320",
  "D7321",
];

export const SRP_PROCEDURES = [
  "D4341",
  "D4342",
  "4341",
  "4342",
  "D4240",
  "D4241",
  "D4260",
  "D4261",
  "D4263",
  "D4264",
  "D4265",
  "D4266",
  "D4267",
  "D4910",
  "D4921",
];

export const SRP_AND_PERIO_CRITERIA_PROCEDURES = [
  "D4341",
  "D4342",
  "D4381",
  "D4240",
  "D4241",
  "D4260",
  "D4261",
  "D4263",
  "D4264",
  "D4265",
  "D4266",
  "D4267",
  "D4245",
  "D4286",
  "D4268",
];

export const CROWN_PROCEDURES = [
  "D2710",
  "D2712",
  "D2720",
  "D2721",
  "D2722",
  "D2740",
  "D2750",
  "D2751",
  "D2752",
  "D2780",
  "D2781",
  "D2782",
  "D2783",
  "D2790",
  "D2791",
  "D2792",
  "D2794",
  "D2931",
];
export const CORE_PROCEDURES = ["D2950", "D2952", "D2954"];
export const POST_AND_CORE_PROCEDURES = ["D2952", "D2954"];
export const ONLAY_PROCEDURES = [
  "D2542",
  "D2543",
  "D2544",
  "D2642",
  "D2643",
  "D2644",
  "D2662",
  "D2663",
  "D2664",
];
export const VENEER_PROCEDURES = ["D2960", "D2961", "D2962"];
export const EXTRACTION_PROCEDURES = ["D7210", "D7140", "D7111", "D7250"];
export const BRIDGE_PROCEDURES = [
  "D6205",
  "D6210",
  "D6211",
  "D6212",
  "D6214",
  "D6240",
  "D6241",
  "D6242",
  "D6245",
  "D6250",
  "D6251",
  "D6252",
  "D6545",
  "D6548",
  "D6549",
  "D6600",
  "D6601",
  "D6602",
  "D6603",
  "D6604",
  "D6605",
  "D6606",
  "D6607",
  "D6608",
  "D6609",
  "D6610",
  "D6611",
  "D6612",
  "D6613",
  "D6614",
  "D6615",
  "D6624",
  "D6634",
  "D6710",
  "D6720",
  "D6721",
  "D6722",
  "D6740",
  "D6750",
  "D6751",
  "D6752",
  "D6780",
  "D6781",
  "D6781",
  "D6782",
  "D6783",
  "D6790",
  "D6791",
  "D6792",
  "D6794",
];

export const IMPACTION_PROCEDURES = ["D7210", "D7240", "D7230", "D7220"];
export const IMPLANTS_PROCEDURES = [
  "D6010",
  "D6011",
  "D6012",
  "D6013",
  "D6040",
  "D6050",
  "D6055",
  "D6056",
  "D6057",
  "D6058",
  "D6059",
  "D6060",
  "D6061",
  "D6062",
  "D6063",
  "D6064",
  "D6065",
  "D6066",
  "D6067",
  "D6068",
  "D6069",
  "D6070",
  "D6071",
  "D6072",
  "D6073",
  "D6074",
  "D6075",
  "D6076",
  "D6077",
  "D6080",
  "D6081",
  "D6082",
  "D6083",
  "D6084",
  "D6085",
  "D6086",
  "D6087",
  "D6088",
  "D6090",
  "D6091",
  "D6092",
  "D6093",
  "D6095",
  "D6096",
  "D6097",
  "D6098",
  "D6099",
  "D6100",
  "D6101",
  "D6102",
  "D6103",
  "D6104",
  "D6105",
  "D6106",
  "D6107",
  "D6110",
  "D6111",
  "D6112",
  "D6113",
  "D6114",
  "D6115",
  "D6116",
  "D6117",
  "D6118",
  "D6119",
  "D6120",
  "D6121",
  "D6122",
  "D6123",
  "D6190",
  "D6191",
  "D6192",
  "D6194",
  "D6195",
  "D6197",
  "D6198",
  "D6501",
  "D6051",
  "D6199",
  "D6094",
];
export const toothBasedProcedure = [
  "D4212",
  "D4245",
  "D4249",
  "D4263",
  "D4264",
  "D4265",
  "D4266",
  "D4267",
  "D4268",
  "D4270",
  "D4273",
  "D4274",
  "D4275",
  "D4276",
  "D4277",
  "D4278",
  "D4381",
  "D4283",
  "D4285",
  "D4286",
  "D4381",
  "D5520",
  "D5630",
  "D5640",
  "D5650",
  "D5660",
  "D5862",
  "D5867",
  "D6010",
  "D6040",
  "D6050",
  "D6094",
  "D6100",
  "D7210",
  "D7250",
  "D7140",
  "D7111",
  "D7953",
];
export const quadrantBasedProcedure = [
  "D4210",
  "D4211",
  "D4240",
  "D4241",
  "D4260",
  "D4261",
  "D4341",
  "D4342",
  "D4921",
  "D5284",
  "D5286",
  "D7310",
  "D7311",
  "D7320",
  "D7321",
];

export const noToohNoQuadrantProcedure: Array<string> = [
  "D0140",
  "D0210",
  "D0220",
  "D0230",
  "D0330",
  "D0367",
  "D0460",
  "D0470",
  "D4245",
  "D4286",
  "D4910",
  "D5130",
  "D5214",
  "D5410",
  "D5411",
  "D5421",
  "D5422",
  "D5511",
  "D5670",
  "D5711",
  "D5820",
  "D5863",
  "D5865",
  "D5899",
  "D6199",
  "D9110",
  "D7921",
  "D7950",
  "D7951",
  "D9223",
  "D9222",
  "D9613",
  "D9630",
  "D9230",
  "D9612",
  "D9610",
  "D9248",
  "D9110",
];

export const archBasesProcedure = [
  "D5110",
  "D5214",
  "D5120",
  "D5130",
  "D5140",
  "D5211",
  "D5212",
  "D5213",
  "D5214",
  "D5221",
  "D5222",
  "D5223",
  "D5224",
  "D5225",
  "D5226",
  "D5282",
  "D5283",
  "D5511",
  "D5512",
  "D5611",
  "D5612",
  "D5621",
  "D5622",
  "D5670",
  "D5671",
  "D5710",
  "D5711",
  "D5720",
  "D5721",
  "D5730",
  "D5731",
  "D5740",
  "D5741",
  "D5750",
  "D5751",
  "D5760",
  "D5761",
  "D5810",
  "D5811",
  "D5820",
  "D5821",
  "D5850",
  "D5851",
  "D5863",
  "D5864",
  "D5865",
  "D5866",
  "D5875",
  "D5876",
  "D5899",
  "D5932",
  "D5913",
  "D5931",
  "D5933",
  "D5936",
  "D5982",
  "D5984",
  "D5985",
  "D5986",
  "D5988",
  "D5991",
  "D5992",
  "D5993",
  "D5995",
  "D5996",
  "D5999",
];
export const superNumerary = [
  "SN",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "80",
  "81",
  "82",
  "AS",
  "BS",
  "CS",
  "DS",
  "ES",
  "FS",
  "GS",
  "HS",
  "IS",
  "JS",
  "KS",
  "LS",
  "MS",
  "NS",
  "OS",
  "PS",
  "QS",
  "RS",
  "SS",
  "TS",
  "1S",
  "2S",
  "3S",
  "4S",
  "5S",
  "6S",
  "7S",
  "8S",
  "9S",
  "10S",
  "11S",
  "12S",
  "13S",
  "14S",
  "15S",
  "16S",
  "17S",
  "18S",
  "19S",
  "20S",
  "21S",
  "22S",
  "23S",
  "24S",
  "25S",
  "26S",
  "27S",
  "28S",
  "29S",
  "30S",
  "31S",
  "32S",
];
