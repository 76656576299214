import {
  OrthoCanvasInformationObject,
  OrthoCanvasPredictionObject,
} from "../context/ortho/types";
import { OrthoClaim } from "../containers/new-design/ortho/OrthoClaim";
import { OrthoClaimImagePrediction } from "../context/ortho/OrthoClaimImagePrediction";

class OrthoServiceDef {
  getOrthoClaimInformation(canvasInformation: OrthoCanvasInformationObject) {
    let orthoObject = new OrthoClaim(canvasInformation);
    const claimInformation = orthoObject.getOrthoClaimInformation();
    const {
      allImages,
      sortedAllTemplateImages,
      showIntraImages,
      showNonIntraImages,
      templateIntraImages,
      templateNonIntraImages,
      additionalImages,
      imagesQueue,
    } = orthoObject.getTemplateImages();
    return {
      claimInformation,
      allImages,
      sortedAllTemplateImages,
      showIntraImages,
      showNonIntraImages,
      templateIntraImages,
      templateNonIntraImages,
      additionalImages,
      imagesQueue,
    };
  }

  getOrthoImagePrediction(
    claimLevelCanvasPrediction: OrthoCanvasPredictionObject
  ) {
    try {
      const orthoClaimImagePrediction = new OrthoClaimImagePrediction(
        claimLevelCanvasPrediction
      );
      const orthoImagePredictions =
        orthoClaimImagePrediction.getImagePredictions();
      return {
        orthoImagePredictions,
      };
    } catch (error) {
      // [todo]: We should throw ortho custom error
    }
  }
}

const orthoServiceDef = new OrthoServiceDef();
export default orthoServiceDef;
