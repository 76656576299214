export const ERROR_TYPES = {
  FAILED_FETCHING: "Failed while fetching",
  UNABLE_LOADING: "Unable to load",
};

export const ERROR_MSGS = {
  GRAPHQL_URI_MISSING: "Unable to proceed. Provide graphql URI",
  CLIENT_CONFIG_MISSING: "Missing Client Config",
  CLAIMS: {
    FETCHING: "Unable to fetch data for claims",
  },
  CLAIM: {
    FETCHING: "Unable to fetch data for the claim",
    PREDICTION: "Unable to fetch prediction data for the claim",
  },
  NO_PREDICTION_FOR_QUADRANT: "No predictions possible for this quadrant",
  UNABLE_LOAD_USER_DETAILS:
    "Oops! Something went wrong while loading user profile. Please try again later.",
  LOGOUT_ERROR: "Error logging out",
};

export const GENERIC_ERRORS = {
  SOMETHING_WRONG: "Oops! Something went wrong",
};

export const AUTH_ERRORS = {
  GENERAL_ERROR: "Error logging out",
};

export const CANVAS_ERRORS = {
  UNABLE_TO_FETCH: "Unable to fetch data for image",
  UNABLE_TO_RELEASE_LOCK: "Unable to release current claim lock",
};
