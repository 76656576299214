import { IClaims } from "src/defs";
import { CacheKeys } from "src/constants/cache-keys";
import cacheService, { CacheService } from "./cache.service";
import { _ } from "src/shared/libs";
import {
  FWADuplicatesConfig,
  FWADuplicatesConfigReset,
  FWASummaryConfig,
  FWASummaryConfigReset,
} from "src/configs/components-config";
import { extractNestedObject } from "src/components/shared/helper";

export class FWAConfigService {
  private _fwaDuplicatesConfig = this.getFWADuplicatesDefaultConfig();
  private _fwaSummaryConfig = this.getFWASummaryDefaultConfig();
  private _selectedRowId: string | undefined = undefined;
  private _visibleCols: IClaims.IVisibleColsDictionary = [] as any;

  constructor(private _cacheService: InstanceType<typeof CacheService>) {
    const fwaDefaultConfig = _cacheService.getItemsSync(
      CacheKeys.FWADuplicatesConfig
    );
    const fwaSummaryConfig = _cacheService.getItemsSync(
      CacheKeys.FWASummaryConfig
    );
    if (fwaDefaultConfig) this.setFWADuplicatesConfig(fwaDefaultConfig, false);
    if (fwaSummaryConfig) this.setFWASummaryConfig(fwaSummaryConfig, false);
  }

  setVisibleColumnsFromCache() {
    if (!_.isEmpty(this._visibleCols)) return this._visibleCols;

    const userProfile = this._cacheService.getItemsSync(CacheKeys.UserProfile);
    if (!userProfile) return [];

    let mapped: any;

    if (userProfile?.clientConfig?.fraudWasteAbuse?.clientHeadersConfig) {
      mapped = userProfile.clientConfig.fraudWasteAbuse.clientHeadersConfig;
    } else {
      // Each time when new client logged in, this will execute and show header from clientHeader
      const clientHeaders =
        extractNestedObject(
          userProfile,
          "clientConfig",
          "fraudWasteAbuse",
          "clientHeaders"
        ) || [];
      mapped = clientHeaders.map((colName: string) => ({
        colName,
        checked: true,
      }));
    }

    const entities = _.keyBy(mapped, "colName");
    this._visibleCols = _.keyBy(entities, "colName");
  }

  getFWADuplicatesDefaultConfig() {
    return {
      search: FWADuplicatesConfig.default.search,
      startDate: FWADuplicatesConfig.default.startDate,
      endDate: FWADuplicatesConfig.default.endDate,
      filter: FWADuplicatesConfig.default.filter as any,
      page: {
        offset: FWADuplicatesConfig.default.pageOffset,
        size: FWADuplicatesConfig.default.pageSize,
      },
    };
  }

  getFWADuplicatesResetDefaultConfig() {
    return {
      startDate: FWADuplicatesConfigReset.default.startDate,
      endDate: FWADuplicatesConfigReset.default.endDate,
      filter: FWADuplicatesConfigReset.default.filter as any,
      search: FWADuplicatesConfigReset.default.search,
      page: {
        offset: FWADuplicatesConfigReset.default.pageOffset,
        size: FWADuplicatesConfigReset.default.pageSize,
      },
    };
  }
  getFWASummaryDefaultConfig() {
    return {
      search: FWASummaryConfig.default.search,
      startDate: FWASummaryConfig.default.startDate,
      endDate: FWASummaryConfig.default.endDate,
      filter: FWASummaryConfig.default.filter as any,
      page: {
        offset: FWASummaryConfig.default.pageOffset,
        size: FWASummaryConfig.default.pageSize,
      },
    };
  }

  getFWASummaryResetDefaultConfig() {
    return {
      startDate: FWASummaryConfigReset.default.startDate,
      endDate: FWASummaryConfigReset.default.endDate,
      filter: FWASummaryConfigReset.default.filter as any,
      search: FWASummaryConfigReset.default.search,
      page: {
        offset: FWASummaryConfigReset.default.pageOffset,
        size: FWASummaryConfigReset.default.pageSize,
      },
    };
  }
  setFWADuplicatesConfig(config: any, persistant = false) {
    this._fwaDuplicatesConfig = config;
    if (persistant) {
      this._cacheService.setItemSync(CacheKeys.FWADuplicatesConfig, config);
    }
  }
  getFWADuplicatesConfig() {
    return this.getFWADuplicatesDefaultConfig();
  }
  setFWASummaryConfig(config: any, persistant = false) {
    this._fwaSummaryConfig = config;
    if (persistant) {
      this._cacheService.setItemSync(CacheKeys.FWASummaryConfig, config);
    }
  }
  getFWASummaryConfig() {
    return this.getFWASummaryDefaultConfig();
  }
  getSelectedRowId() {
    return this._selectedRowId;
  }
  setSelectedRowId(id: string) {
    const rowSelect = (id: string) =>
      document.querySelectorAll(`table tr[data-row-key="${id}"]`);
    if (this._selectedRowId)
      rowSelect(this._selectedRowId).forEach((ele) =>
        ele.removeAttribute("id")
      );

    rowSelect(id).forEach((ele) => ele.setAttribute("id", "ia-selected-row"));
    this._selectedRowId = id;
  }
  crawlToSelectedRow() {
    const rowId = this.getSelectedRowId();
    const element = document.querySelectorAll(
      `table tr[data-row-key="${rowId}"]`
    );
    if (!element) return;

    element.forEach((e) => {
      e.setAttribute("id", "ia-selected-row");
      e.scrollIntoView({ block: "center" });
    });
  }

  setVisibleCols(cols: IClaims.IVisibleColsDictionary) {
    this._visibleCols = cols;
  }

  getVisibleCols() {
    return this._visibleCols;
  }

  flush() {
    this.setVisibleCols([] as any);
    this.setSelectedRowId("");
    this.setFWADuplicatesConfig(this.getFWADuplicatesDefaultConfig());
    this.setFWASummaryConfig(this.getFWASummaryDefaultConfig());
  }
}

const fwaConfigService = new FWAConfigService(cacheService);
export default fwaConfigService;
