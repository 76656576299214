import React from "react";

import { cacheService } from "src/services";

export default function WithClientConfigBoundary(
  WrappedComponent: React.JSXElementConstructor<any>
) {
  return class ClientConfigBoundary extends React.PureComponent {
    async componentDidCatch(e: any) {
      cacheService.clear();
      window.location.reload();
    }

    render() {
      if (!WrappedComponent) return null;
      return <WrappedComponent {...this.props} />;
    }
  };
}
