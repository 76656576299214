import React from "react";
import { Spin } from "antd";

interface IProps {
  style: { [x: string]: any };
}

export default function GlobalLoader(props: IProps) {
  return (
    <Spin
      className="automate"
      style={{
        alignItems: "center",
        display: "flex",
        height: "98vh",
        justifyContent: "center",
        ...props.style,
      }}
      size="large"
    />
  );
}

GlobalLoader.defaultProps = {
  style: {},
};
