import { GenericObject } from "src/defs";
import { PredictionObject } from "src/components/new-design/canvas/types";
import NO_XRAY_IMAGE_SOURCE from "src/assets/images/no-valid-xray.png";
import NO_IMAGE_ON_CLAIM_SOURCE from "src/assets/images/no-image-on-claim.png";
import "./index.scss";

export const SOURCE_IMAGE_EMPTY_PREDICTION: PredictionObject = {
  imageUri: undefined,
  prediction: {
    classifications: {
      image_type: "source",
    },
  },
  reasons: [],
  sourceImages: undefined,
  uniqueId: "",
  imageReceiptDate: {},
  isNewTag: false,
};

export const FURCATION_FINDING = {
  parentObjectName: "furcation",
  geometry_type: "bbox",
  color: "",
  type: ["Furcation"],
  subType: "Furcation",
  metaclass_type: "Furcation",
  tooth_number: [],
  geometry: [],
};

export const FURCATION_FINDING_2 = {
  parentObjectName: "furcation",
  geometry_type: "bbox",
  color: "184, 233, 134",
  type: ["Furcation"],
  subType: "Furcation",
  metaclass_type: "Furcation",
  tooth_number: [],
  geometry: [],
};

export const FINDING_LABELS: GenericObject = {
  bone_loss: "Bone Loss",
  global: "Max Bone Level",
  tooth: "Tooth",
};

export const HEADING_LABELS = {
  Criteria_Met: "Acceptance Criteria Met",
  Criteria_Not_Met: "Acceptance Criteria Not Met",
  Findings: "Findings",
};

export const NO_XRAY_FOUND = {
  imageUri: NO_XRAY_IMAGE_SOURCE,
  prediction: {},
  sourceImages: NO_XRAY_IMAGE_SOURCE,
};

export const BONE_LEVEL_MAX_BONE_LOSS = 20.0;

export const KID_TOOTH_NUMBERS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
];

export const NO_IMAGE_CLAIM = {
  uniqueId: "NO_IMAGE",
  imageUri: NO_IMAGE_ON_CLAIM_SOURCE,
  match: true,
};

export const adjacentToothList: GenericObject = {
  1: [2, 3],
  2: [1, 3, 4],
  3: [1, 2, 4, 5],
  4: [2, 3, 5, 6],
  5: [3, 4, 6, 7],
  6: [4, 5, 7, 8],
  7: [5, 6, 8, 9],
  8: [6, 7, 9, 10],
  9: [7, 8, 10, 11],
  10: [8, 9, 11, 12],
  11: [9, 10, 12, 13],
  12: [10, 11, 13, 14],
  13: [11, 12, 14, 15],
  14: [12, 13, 15, 16],
  15: [13, 14, 16],
  16: [14, 15],
  17: [18, 19],
  18: [17, 19, 20],
  19: [17, 18, 20, 21],
  20: [18, 19, 21, 22],
  21: [19, 20, 22, 23],
  22: [20, 21, 23, 24],
  23: [21, 22, 24, 25],
  24: [22, 23, 25, 26],
  25: [23, 24, 26, 26],
  26: [24, 25, 27, 28],
  27: [25, 26, 28, 29],
  28: [26, 27, 29, 30],
  29: [27, 28, 30, 31],
  30: [28, 29, 31, 32],
  31: [29, 30, 32],
  32: [30, 31],
};

export const adjacentToothListPano: GenericObject = {
  1: [2, 3],
  2: [3, 4],
  3: [2, 4, 5],
  4: [2, 3, 5, 6],
  5: [3, 4, 6, 7],
  6: [4, 5, 7, 8],
  7: [5, 6, 8, 9],
  8: [6, 7, 9, 10],
  9: [7, 8, 10, 11],
  10: [8, 9, 11, 12],
  11: [9, 10, 12, 13],
  12: [10, 11, 13, 14],
  13: [11, 12, 14, 15],
  14: [12, 13, 15],
  15: [13, 14],
  16: [14, 15],
  17: [18, 19],
  18: [19, 20],
  19: [18, 20, 21],
  20: [18, 19, 21, 22],
  21: [19, 20, 22, 23],
  22: [20, 21, 23, 24],
  23: [21, 22, 24, 25],
  24: [22, 23, 25, 26],
  25: [23, 24, 26, 26],
  26: [24, 25, 27, 28],
  27: [25, 26, 28, 29],
  28: [26, 27, 29, 30],
  29: [27, 28, 30, 31],
  30: [28, 29, 31],
  31: [29, 30],
  32: [30, 31],
};
