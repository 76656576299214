export const COLORS: any = {
  BLUE: "#42A5F5",
  BROWN: "#8D6E63",
  RED: "#D62926",
  GREEN: "#347B38",
  ORANGE: "#FF7043",
  BLUEGRAY: "#78909C",
  CHARCOAL: "#2D3446",
  CHARCOALLIGHT: "#788195",
  CULTURED: "#F2F3F7",
  LIGHTGRAY: "#EAEBEF",
  WHITE: "#FFFFFF",
  PURPLE: "#542EB8",
  AMBER: "#ED7200",
  BLACK: "#000000",
  // CYAN: "#0DA8CA",
  YELLOWISH: "#DEAD00",
  GREENISH: "#3BAA96",
};

export const overjetReviewColorDarkMode = {
  DENY: "#FF8684",
  CLINICAL_REVIEW: "#B498FF",
  ACCEPT: "#A8ECAB",
  PARTIAL_DENY: "#F1DEAE",
  PEND: "#E0AF9E",
  REVIEW: "#80C6FF",
  RFI: "#8FD8CB",
  PARTIAL_ACCEPT: "#F1DEAE",
};

export const overjetReviewColorLightMode = {
  DENY: "#DF332F",
  CLINICAL_REVIEW: "#542EB8",
  DOCUMENTATION_REQUEST: "#8D6E63",
  APPROVAL_PROCESSING: "#66BB6A",
  ACCEPT: "#4AB14F",
  PARTIAL_DENY: "#DEAD00",
  PEND: "#B99385",
  REVIEW: "#42A5F5",
  RFI: "#3BAA96",
  PARTIAL_ACCEPT: "#DEAD00",
};
