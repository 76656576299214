import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

export const ClaimsTableConfig = {
  default: {
    procedureType: ["ALL"],
    pageSize: 30,
    pageOffset: 0,
    filter: [],
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    search: "",
    ingressFilter: [],
    stateFilter: [],
    systemFilter: [],
    specialtyReviewFilter: "ALL",
  },
  pageSizeOptions: ["5", "15", "30", "50"],
  defaultSort: [{ property: "overjetReviewResult", direction: "ASC" }],
  customSort: [{ property: "review.decision.result", direction: "DESC" }],
};

export const ClaimsTableConfigReset = cloneDeep(ClaimsTableConfig);

export const FWADuplicatesConfig = {
  default: {
    pageSize: 30,
    pageOffset: 0,
    filter: [{ property: "confidenceType", value: "C90" }],
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    search: "",
  },
  pageSizeOptions: ["5", "15", "30", "50"],
};

export const FWADuplicatesConfigReset = cloneDeep(FWADuplicatesConfig);

export const FWASummaryConfig = {
  default: {
    pageSize: 30,
    pageOffset: 0,
    filter: [],
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    search: "",
  },
  pageSizeOptions: ["5", "15", "30", "50"],
};

export const FWASummaryConfigReset = cloneDeep(FWASummaryConfig);

export const SummaryFilterConfig = {
  default: {
    procedureType: "ALL",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    search: "", // This search is using for analytics call, but its unnecessary right now.
  },
};
export const SummaryFilterConfigReset = cloneDeep(SummaryFilterConfig);

// * Periods here are in Seconds
export const SessionConfig = {
  refreshPeriod: 1800,
  sessionPeriod: 3600,
};
