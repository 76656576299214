import * as auth from "./auth.service";
import * as cache from "./cache.service";
import * as fmxService from "./fmx.service";
import * as claimsTable from "./claims-table.service";
import * as summary from "./summary.service";
import * as gainsight from "./gainsight.service";
import * as guards from "./guards.service";
import * as user from "./user.service";
import * as interaction from "./interaction-observer.service";
import * as fwa from "./fwa.service";
import * as query from "./query.service";
import * as queueTable from "./queue-table.service";
import * as orthoServiceDef from "./ortho.service";

export const authService = auth.default;
export const AuthService = auth.AuthService;

export const FMXService = fmxService.default;
export const FmxService = fmxService.FMXservice;

export const cacheService = cache.default;
export const CacheService = cache.CacheService;

export const claimsTableService = claimsTable.default;
export const ClaimsTableService = claimsTable.ClaimsTableService;

export const gainsightService = gainsight.default;
export const GainsightService = gainsight.GainsightService;

export const guardsService = guards.default;
export const GuardsService = guards.GuardsService;

export const userService = user.default;
export const UserService = user.UserService;

export const interactionService = interaction.default;
export const InteractionService = interaction.IntractionService;

export const summaryConfigService = summary.default;
export const SummaryConfigService = summary.SummaryConfigService;

export const fwaConfigService = fwa.default;
export const FWAConfigService = fwa.FWAConfigService;

export const queryService = query.default;
export const QueryService = query.QueryService;

export const queueTableService = queueTable.default;
export const QueueTableService = queueTable.QueueTableService;

export const orthoService = orthoServiceDef.default;
