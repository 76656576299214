import retry from "retry";
import { UserProfile } from "src/services/types";
import eventsService, { EventsService } from "src/services/events.service";
import userService from "src/services/user.service";
import { EventConstants } from "src/components/new-design/utility/constants";
import {
  ServiceLineObject,
  ClaimInformationObject,
} from "src/context/canvas/types";
import { InsuranceClaimRecord } from "src/components/shared/claimTable/types";
import { getToothOrQuadrantValue } from "src/components/new-design/canvas/canvas-helper";
import { ReviewOption } from "src/components/new-design/canvas/reviewer-actions/types";

declare global {
  interface Window {
    aptrinsic: GainsightAptrinsic;
  }
}

/**
 * This will get the aptrinsic call, but only when it's fully loaded and initialized.
 */
const getAptrinsic = () =>
  typeof window.aptrinsic === "function" && window.aptrinsic.init
    ? window.aptrinsic
    : undefined;

interface GainsightAptrinsic {
  /**
   * How to tell if the aptrinsic javascript is loaded correctly
   * ```
   * aptrinsic.init
   * -> true
   * ```
   *
   * https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK#How_to_Validate_that_the_Gainsight_PX_Tag_has_been_Installed
   */
  init?: boolean;

  /**
   * Reset/Logout
   * ---
   * Gainsight PX allows you to explicitly call the logout functionality which resets the tracked session.
   * Logout is useful for an app that allows users to roam between subscriptions within the same browser
   * and where you’d like to split the tracked session into two separate sessions.
   *
   * https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK#Reset.2FLogout
   */
  (verb: "reset"): void;

  /**
   * Identify your Users (Post Successful Login)
   * ---
   * This is a call that needs to be added to the authentication section of your web application so that
   * those events captured from the Tag Setup can be associated to the correct User/Account.
   *
   * When sending user and/or account data, you may want to include attributes on that user or account
   * that are not part of the default set.  We call these custom attributes and you can add your own set
   * of custom attributes from within your Gainsight PX subscription by navigating to
   * "Administration > Attributes" page
   *
   * https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK#Identify_your_Users_(Post_Successful_Login)
   */
  (
    verb: "identify",
    userFields: GainsightUserFields,
    accountFields: GainsightAccountFields
  ): void;

  /**
   * Custom Events
   * ---
   * The Gainsight PX Web SDK supports the ability to send custom events.
   * You include a name for the custom event as well as properties/values for that event.
   *
   * The types supported are:
   *   * string
   *   * number
   *   * boolean
   *   * datetime (ISO 8601)
   *   * nested events will be saved as string
   *
   * https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK#Custom_Events
   */
  (
    verb: "track",
    track: GainsightCustomEvents,
    data?: Partial<GainsightEventFields>
  ): void;
}

interface GainsightUserFields {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
}
interface GainsightAccountFields {
  id?: string;
  name?: string;
}
interface GainsightEventFields {
  aiDecisionCode?: string;
  aiDecisionDescription?: string;
  aiDecisionResult?: string;
  claimId?: string;
  claimType?: string;
  clientDecisionCode?: string;
  clientDecisionDescription?: string;
  clientDecisionResult?: string;
  procedureId?: string;
  procedure?: string;
  procedureCount?: string;
  toothOrQuadrant?: string;
}

/**
 * Gainsight Custom Events follow the following pattern:
 * [page]--[verb]--[noun]
 */
enum GainsightCustomEvents {
  ALL_SELECT_CLAIM = "all--select--claim",
  QUEUE_SELECT_CLAIM = "queue--select--claim",

  CANVAS_BRIGHTNESS_IMAGE = "canvas--brightness--image",
  CANVAS_CONTRAST_IMAGE = "canvas--contrast--image",
  CANVAS_EXIT_CLAIM = "canvas--exit--claim",
  CANVAS_FINISH_DECISION = "canvas--finish--decision",
  CANVAS_LOAD_CLAIM = "canvas--load--claim",
  CANVAS_NEXT_CLAIM = "canvas--next--claim",
  CANVAS_PREVIOUS_CLAIM = "canvas--previous--claim",
  CANVAS_RESET_IMAGE = "canvas--reset--image",
  CANVAS_SELECT_ALL_ANNOTATIONS = "canvas--toggle--all-annotations",
  CANVAS_SELECT_ONE_ANNOTATION = "canvas--toggle--one-annotation",
  CANVAS_ACTIVE_PROCEDURE = "canvas--active--procedure",
  CANVAS_SELECT_PROCEDURE_IMAGE = "canvas--select--procedure-image",
  CANVAS_SELECT_PROCEDURE_IMAGE_ALL_ATTACHMENTS = "canvas--select--procedure-image-all-attachments",
  CANVAS_SELECT_PROCEDURE_TABLE = "canvas--select--procedure-table",
  CANVAS_VIEW_COMMENT = "canvas--view--comment",
  CANVAS_VIEW_KEYBOARD = "canvas--view--keyboard",
  CANVAS_VIEW_NARRATIVE = "canvas--view--narrative",
  CANVAS_ZOOM_IN_IMAGE = "canvas--zoomin--image",
  CANVAS_ZOOM_OUT_IMAGE = "canvas--zoomout--image",
}

/**
 * Map the EventConstants to GainsightCustomEvents
 * ---
 *  If there is special data, it should be handled in the specific event handler
 *  and not in this mapping. These are all events that need to be tracked in Gainsight PX.
 *  But there is no data associated with the event.
 */
const EVENTS_MAP: { [key in EventConstants]?: GainsightCustomEvents } = {
  [EventConstants.TRACK_CANVAS_EXIT_CLAIM]:
    GainsightCustomEvents.CANVAS_EXIT_CLAIM,
  [EventConstants.TRACK_CANVAS_NEXT_CLAIM]:
    GainsightCustomEvents.CANVAS_NEXT_CLAIM,
  [EventConstants.TRACK_CANVAS_PREVIOUS_CLAIM]:
    GainsightCustomEvents.CANVAS_PREVIOUS_CLAIM,
  [EventConstants.TRACK_CANVAS_SELECT_ALL_ANNOTATIONS]:
    GainsightCustomEvents.CANVAS_SELECT_ALL_ANNOTATIONS,
  [EventConstants.TRACK_CANVAS_SELECT_ALL_ATTACHMENTS_IMAGE]:
    GainsightCustomEvents.CANVAS_SELECT_PROCEDURE_IMAGE_ALL_ATTACHMENTS,
  [EventConstants.TRACK_CANVAS_SELECT_ONE_ANNOTATION]:
    GainsightCustomEvents.CANVAS_SELECT_ONE_ANNOTATION,
  [EventConstants.TRACK_CANVAS_SELECT_PROCEDURE_IMAGE]:
    GainsightCustomEvents.CANVAS_SELECT_PROCEDURE_IMAGE,
  [EventConstants.TRACK_CANVAS_SELECT_PROCEDURE_TABLE]:
    GainsightCustomEvents.CANVAS_SELECT_PROCEDURE_TABLE,
  [EventConstants.TRACK_CANVAS_SHOW_KEYBOARD]:
    GainsightCustomEvents.CANVAS_VIEW_KEYBOARD,
  [EventConstants.TRACK_CANVAS_START_COMMENT]:
    GainsightCustomEvents.CANVAS_VIEW_COMMENT,
  [EventConstants.TRACK_CANVAS_VIEW_NARRATIVE]:
    GainsightCustomEvents.CANVAS_VIEW_NARRATIVE,
};

class RetryQueue<T extends (...args: any[]) => void> {
  private callQueue: any[] = [];

  constructor(private _callGetter: () => T | undefined) {}

  call(...args: any[]): void {
    const call = this._callGetter();
    if (call) {
      this.processQueue(call);
      call(...args);
    } else {
      this.callQueue.push(args);
      this.setupRetry();
    }
  }

  private processQueue(call: T): void {
    while (this.callQueue.length > 0) {
      const args = this.callQueue.shift();
      call(...args);
    }
  }

  private setupRetry(): void {
    const operation = retry.operation({
      retries: 5,
      factor: 2,
      minTimeout: 100,
      maxTimeout: 6000,
      randomize: true,
    });

    operation.attempt(() => {
      const aptrinsicCall: T | undefined = this._callGetter();
      if (aptrinsicCall) {
        this.processQueue(aptrinsicCall);
      } else if (operation.retry(new Error("Call not ready"))) {
        return;
      }
    });
  }
}

export class GainsightService {
  private _retry: RetryQueue<GainsightAptrinsic>;
  private _fieldState: Partial<GainsightEventFields>;
  private _user: Partial<GainsightUserFields>;

  constructor(
    _getAptrinsic: () => GainsightAptrinsic | undefined,
    _eventsService: EventsService
  ) {
    _eventsService.on(
      EventConstants.TRACK_ALL_SELECT_CLAIM,
      this.trackReviewClaim.bind(this, "all")
    );
    _eventsService.on(
      EventConstants.TRACK_CANVAS_CLAIM_INFORMATION,
      this.trackClaimInformation.bind(this)
    );
    _eventsService.on(
      EventConstants.TRACK_CANVAS_IMAGE_TOOLS,
      this.trackImageToolEvent.bind(this)
    );
    _eventsService.on(
      EventConstants.TRACK_CANVAS_ACTIVE_PROCEDURE,
      this.trackReviewProcedure.bind(this)
    );
    _eventsService.on(
      EventConstants.TRACK_CANVAS_REVIEW,
      this.trackReviewResult.bind(this)
    );
    _eventsService.on(
      EventConstants.TRACK_QUEUE_SELECT_CLAIM,
      this.trackReviewClaim.bind(this, "queue")
    );
    _eventsService.on(
      EventConstants.TRACK_SET_PROFILE,
      this.identifyUser.bind(this)
    );
    _eventsService.on(
      EventConstants.TRACK_UNSET_PROFILE,
      this.resetUser.bind(this)
    );

    Object.entries(EVENTS_MAP).forEach(([event, gainsightEvent]) => {
      if (gainsightEvent) {
        const eventName = event as EventConstants;
        _eventsService.on(eventName, this.trackEvent.bind(this, eventName));
      }
    });

    this._retry = new RetryQueue(_getAptrinsic);
    this._fieldState = {};
    this._user = {};
  }

  /**
   * Wrap the aptrinsic call.
   * It will handle retrying if aptrinsic hasn't been defined yet.
   */
  _callAptrinsic: GainsightAptrinsic = (...args: any[]) =>
    this._retry.call(...args);

  /**
   * On UNSET_PROFILE, resetUser
   *
   * This will reset the user session with Gainsight PX
   */
  resetUser(): void {
    this._fieldState = {};
    this._callAptrinsic("reset");
  }

  /**
   * On SET_PROFILE, identify user
   *
   * In Gainsight PX we would like to identify our users.
   * This code will map the UserProfile object to Gainsight PX attributes
   *
   * @param userProfile User Profile data
   */
  identifyUser(userProfile: UserProfile): void {
    const userInfo = userProfile.userInformation;
    const userFields: GainsightUserFields = {
      id: userProfile.username || userInfo?.emailId?.replace("@", "-"),
      email: userProfile.email,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      role: userProfile?.roles?.join(", "),
    };
    const accountFields: GainsightAccountFields = {
      id:
        userService.getActiveClientId() || userProfile.clientConfig.clientName,
      name: userProfile.clientConfig.clientName,
    };

    this._user = userFields;
    this._callAptrinsic("identify", userFields, accountFields);
  }

  /**
   * Track the claim information, this happens when the canvas loads.
   * This will also setup the field state for the rest of the events.
   */
  trackClaimInformation(claim: ClaimInformationObject): void {
    const procedureCount = claim.serviceLinesList.length;
    const firstServiceLine =
      procedureCount > 0 ? claim.serviceLinesList[0] : undefined;
    const toothOrQuadrant = firstServiceLine
      ? getToothOrQuadrantValue(firstServiceLine)
      : undefined;

    this._fieldState = {
      ...this._user,
      aiDecisionCode: firstServiceLine?.review?.ai?.decision?.code,
      aiDecisionDescription:
        firstServiceLine?.review?.ai?.decision?.description,
      aiDecisionResult: firstServiceLine?.review?.ai?.decision?.result,
      claimId: claim.claimId,
      claimType: firstServiceLine?.ingressType,
      procedureId: firstServiceLine?.id,
      procedure: firstServiceLine?.procedure,
      procedureCount: procedureCount.toString(),
      toothOrQuadrant: toothOrQuadrant,
    };
    this._callAptrinsic(
      "track",
      GainsightCustomEvents.CANVAS_LOAD_CLAIM,
      this._fieldState
    );
  }

  trackReviewClaim(page: "all" | "queue", claim: InsuranceClaimRecord): void {
    this._fieldState = {
      claimId: claim.claimId,
      claimType: claim.claimType,
      procedureCount: claim.procedure?.split(",").length.toString(),
    };
    if (page === "all") {
      this._callAptrinsic(
        "track",
        GainsightCustomEvents.ALL_SELECT_CLAIM,
        this._fieldState
      );
    } else {
      this._callAptrinsic(
        "track",
        GainsightCustomEvents.QUEUE_SELECT_CLAIM,
        this._fieldState
      );
    }
  }

  trackReviewProcedure(serviceLine: ServiceLineObject): void {
    this._fieldState = {
      ...this._fieldState,
      procedureId: serviceLine.id,
      procedure: serviceLine.procedure,
      toothOrQuadrant: getToothOrQuadrantValue(serviceLine),
    };
    this._callAptrinsic(
      "track",
      GainsightCustomEvents.CANVAS_ACTIVE_PROCEDURE,
      this._fieldState
    );
  }

  trackReviewResult(review: { action: string; option: ReviewOption }): void {
    const { action, option } = review;
    this._fieldState = {
      ...this._fieldState,
      clientDecisionCode: option.value,
      clientDecisionDescription: option.text,
      clientDecisionResult: action,
    };
    this._callAptrinsic(
      "track",
      GainsightCustomEvents.CANVAS_FINISH_DECISION,
      this._fieldState
    );
  }

  trackImageToolEvent(data: { action: string }): void {
    const aptrinsic = this._callAptrinsic;
    switch (data.action) {
      case "reset":
        aptrinsic(
          "track",
          GainsightCustomEvents.CANVAS_RESET_IMAGE,
          this._fieldState
        );
        break;
      case "contrast":
        aptrinsic(
          "track",
          GainsightCustomEvents.CANVAS_CONTRAST_IMAGE,
          this._fieldState
        );
        break;
      case "brightness":
        aptrinsic(
          "track",
          GainsightCustomEvents.CANVAS_BRIGHTNESS_IMAGE,
          this._fieldState
        );
        break;
      case "zoomIn":
        aptrinsic(
          "track",
          GainsightCustomEvents.CANVAS_ZOOM_IN_IMAGE,
          this._fieldState
        );
        break;
      case "zoomOut":
        aptrinsic(
          "track",
          GainsightCustomEvents.CANVAS_ZOOM_OUT_IMAGE,
          this._fieldState
        );
        break;
      default:
        console.error(
          `'${data.action}' doesn't have a mapping in GainsightService.trackImageToolEvent`
        );
        break;
    }
  }

  trackEvent(eventName: EventConstants): void {
    const event = EVENTS_MAP[eventName];
    if (event) {
      this._callAptrinsic("track", event, this._fieldState);
    } else {
      console.error(`'${eventName}' doesn't have a mapping in EVENTS_MAP`);
    }
  }
}

const gainsightService = new GainsightService(getAptrinsic, eventsService);
export default gainsightService;
