import { defer } from "lodash";
import { UserProfile } from "src/services/types";
import { EventConstants } from "src/components/new-design/utility/constants";
import { InsuranceClaimRecord } from "src/components/shared/claimTable/types";
import {
  ServiceLineObject,
  ClaimInformationObject,
} from "src/context/canvas/types";
import { ReviewOption } from "src/components/new-design/canvas/reviewer-actions/types";

type EventHandler<T> = (data: T) => void;
type EventHandlerWithoutData = () => void;

/**
 * Events Service is used to decouple components and services through events.
 *
 * Upstream services will publish events through the `emit` and downstream services will add subscriptions
 * through the `on` method by adding listeners. There is no limit to the number of subscribers to an event.
 */
export interface EventsService {
  on(
    eventName: EventConstants.TRACK_SET_PROFILE,
    handler: EventHandler<UserProfile>
  ): void;
  emit(
    eventName: EventConstants.TRACK_SET_PROFILE,
    userProfile: UserProfile
  ): Promise<boolean>;

  on(
    eventName: EventConstants.TRACK_UNSET_PROFILE,
    handler: EventHandlerWithoutData
  ): void;
  emit(eventName: EventConstants.TRACK_UNSET_PROFILE): Promise<boolean>;

  on(
    eventName: EventConstants.TRACK_ALL_SELECT_CLAIM,
    handler: EventHandler<InsuranceClaimRecord>
  ): void;
  emit(
    eventName: EventConstants.TRACK_ALL_SELECT_CLAIM,
    claim: InsuranceClaimRecord
  ): Promise<boolean>;

  on(
    eventName: EventConstants.TRACK_QUEUE_SELECT_CLAIM,
    handler: EventHandler<InsuranceClaimRecord>
  ): void;
  emit(
    eventName: EventConstants.TRACK_QUEUE_SELECT_CLAIM,
    claim: InsuranceClaimRecord
  ): Promise<boolean>;

  on(
    eventName: EventConstants.TRACK_CANVAS_CLAIM_INFORMATION,
    handler: EventHandler<ClaimInformationObject>
  ): void;
  emit(
    eventName: EventConstants.TRACK_CANVAS_CLAIM_INFORMATION,
    claim: ClaimInformationObject
  ): Promise<boolean>;

  on(
    eventName: EventConstants.TRACK_CANVAS_ACTIVE_PROCEDURE,
    handler: EventHandler<ServiceLineObject>
  ): void;
  emit(
    eventName: EventConstants.TRACK_CANVAS_ACTIVE_PROCEDURE,
    serviceLine: ServiceLineObject
  ): Promise<boolean>;

  on(
    eventName: EventConstants.TRACK_CANVAS_IMAGE_TOOLS,
    handler: EventHandler<{ action: string }>
  ): void;
  emit(
    eventName: EventConstants.TRACK_CANVAS_IMAGE_TOOLS,
    action: { action: string }
  ): Promise<boolean>;

  on(
    eventName: EventConstants.TRACK_CANVAS_REVIEW,
    handler: EventHandler<{ action: string; option: ReviewOption }>
  ): void;
  emit(
    eventName: EventConstants.TRACK_CANVAS_REVIEW,
    review: { action: string; option: ReviewOption }
  ): Promise<boolean>;

  on(eventName: EventConstants, handler: EventHandler<undefined>): void;
  emit(eventName: EventConstants): Promise<boolean>;

  /** reset the event service, this is for testing purposes */
  reset(): void;
}

/**
 * _EventsService implementation is private,
 * consumers of this service should use the exported interface
 *
 * The interface will ensure that EventName maps to a specific datatype
 * The implementation is generic and is unaware of the mapping
 */
class _EventsService implements EventsService {
  private _events: Record<
    string,
    (EventHandler<any> & EventHandlerWithoutData)[]
  > = {};

  constructor() {
    this._events = {};
  }

  reset() {
    this._events = {};
  }

  on<T>(
    eventName: EventConstants,
    handler: EventHandler<T> & EventHandlerWithoutData
  ): void {
    if (!this._events[eventName]) {
      this._events[eventName] = [handler];
    } else {
      this._events[eventName].push(handler);
    }
  }

  emit<T>(eventName: EventConstants, data?: T): Promise<boolean> {
    const handlers = this._events[eventName];
    if (handlers) {
      // Using defer to keep the emit call as non-blocking
      return new Promise((done) => {
        defer(() => {
          try {
            typeof data != "undefined"
              ? handlers.forEach((handler) => handler(data))
              : handlers.forEach((handler) => handler());
          } finally {
            done(true);
          }
        });
      });
    }
    return Promise.resolve(false);
  }
}

const eventsService: EventsService = new _EventsService();
export default eventsService;
